import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function SideBarItem({ link, icon: Icon, label }) {
  const navActiveClasses = 'text-gray-900 bg-gray-100';
  const defaultClasses = 'text-gray-600';
  const basicClasses = 'hover:bg-gray-100 rounded-md flex space-x-3 items-center p-2';

  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        classNames(basicClasses, {
          [navActiveClasses]: isActive,
          [defaultClasses]: !isActive,
        })
      }
    >
      {({ isActive }) => (
        <>
          <Icon className={classNames('size-6', { 'text-indigo-600 stroke-2': isActive })} />
          <span className="flex-grow">{label}</span>
        </>
      )}
    </NavLink>
  );
}

SideBarItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.any,
};
SideBarItem.defaultProps = {
  link: null,
  icon: null,
};
