import classNames from 'classnames';
import { EmojiGrinningFace, EmojiNeutralFace, EmojiFrowningFace } from 'components/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';

const images = [
  { name: 'frowning', component: EmojiFrowningFace },
  { name: 'neutral', component: EmojiNeutralFace },
  { name: 'grinning', component: EmojiGrinningFace },
];

export default function EmojiSelect({ onClick, isSelected, label, emoji }) {
  const Emoji = _.find(images, ['name', emoji])?.component ?? EmojiGrinningFace;

  const selectedClasses = 'border-emerald-500 bg-emerald-50 border-2';
  const defaultClasses = 'border-gray-300 bg-white border';
  const baseClasses = 'flex flex-grow space-x-2 rounded-lg py-3 items-center justify-center cursor-pointer';
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(baseClasses, {
        [selectedClasses]: isSelected,
        [defaultClasses]: !isSelected,
      })}
    >
      <Emoji className="size-8" />
      <span>{label}</span>
    </button>
  );
}

EmojiSelect.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  emoji: PropTypes.string,
};

EmojiSelect.defaultProps = {
  onClick: _.noop,
  isSelected: false,
  label: '',
  emoji: 'neutral',
};
