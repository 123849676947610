import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Paper, Table, Badge } from '@mantine/core';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components/ui-messages';
import _ from 'lodash';

const getBadgeProps = (positive, neutral = false) => {
  if (positive) {
    return { color: 'indigo.2', className: 'text-indigo-500' };
  }
  return neutral ? { color: 'gray.2', className: 'text-gray-500' } : { color: 'red.2', className: 'text-red-500' };
};
const getFormattedDate = (date) => {
  return date ? format(parseISO(date), 'dd/MM/yyyy') : '';
};
const getFormattedTime = (date) => {
  return date ? format(parseISO(date), 'hh:mm') : '';
};
export default function ReportsTable({ data, onRowClick, isLoading }) {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return _.map(data, (item) => (
      <Table.Tr
        key={item.id}
        className="text-gray-900 cursor-pointer"
        onClick={() => {
          onRowClick(item);
        }}
      >
        <Table.Td align="center">{item.id}</Table.Td>
        <Table.Td>
          <div className="flex flex-col">
            <span>{getFormattedDate(item.createdAt)}</span>
            <span className="text-sm text-gray-400"> {getFormattedTime(item.createdAt)}</span>
          </div>
        </Table.Td>
        <Table.Td className="text-nowrap line-clamp-2">{item.institution}</Table.Td>
        <Table.Td>{item.institutionCategory}</Table.Td>
        <Table.Td>{item.locality}</Table.Td>
        <Table.Td>{item.satisfaction}</Table.Td>
        <Table.Td>{item.ageGroup}</Table.Td>
        <Table.Td>{item.sex}</Table.Td>
        <Table.Td align="center">
          <Badge p="12px" {...getBadgeProps(item.medicalInsurancePolicy, true)}>
            {t(item.medicalInsurancePolicy ? 'labels.insured' : 'labels.uninsured')}
          </Badge>
        </Table.Td>
        <Table.Td align="center">
          <Badge p="12px" {...getBadgeProps(!item.unofficialPayments)}>
            {item.unofficialPayments ? 'DA' : 'NU'}
          </Badge>
        </Table.Td>
        <Table.Td>{item.comments}</Table.Td>
      </Table.Tr>
    ));
  }, [data, onRowClick, t]);
  return (
    <Paper radius="8px" withBorder className="overflow-hidden">
      <Table.ScrollContainer minWidth="100%">
        <Table highlightOnHover classNames={{ th: 'font-medium py-3' }}>
          <Table.Thead className="bg-gray-50 text-gray-500 py-6">
            <Table.Tr>
              <Table.Th>{t('tables.id')}</Table.Th>
              <Table.Th>{t('tables.created-at')}</Table.Th>
              <Table.Th>{t('tables.institution')}</Table.Th>
              <Table.Th>{t('tables.institution-category')}</Table.Th>
              <Table.Th>{t('tables.location')}</Table.Th>
              <Table.Th>{t('tables.general-impression')}</Table.Th>
              <Table.Th>{t('tables.age-group')}</Table.Th>
              <Table.Th>{t('tables.gender')}</Table.Th>
              <Table.Th className="text-center">{t('tables.insurance')}</Table.Th>
              <Table.Th className="text-center">{t('tables.unofficial-payments')}</Table.Th>
              <Table.Th>{t('tables.comments')}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {isLoading && (
              <>
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
              </>
            )}
            {!isLoading && rows}
          </Table.Tbody>
        </Table>
        {!isLoading && _.isEmpty(data) && <EmptyState message={t('messages.no-results')} className="w-full" />}
      </Table.ScrollContainer>
    </Paper>
  );
}

ReportsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
};
ReportsTable.defaultProps = {
  data: [],
  isLoading: false,
  onRowClick: _.noop,
};

function RowSkeleton() {
  return (
    <Table.Tr>
      <Table.Td align="center">
        <div className="h-6 w-8 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-60 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td align="center">
        <div className="h-6 w-20 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td align="center">
        <div className="h-6 w-20 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
    </Table.Tr>
  );
}
