import { useCallback, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconX, IconCheck } from '@tabler/icons-react';
import { rem } from '@mantine/core';

export const useStateDisclosure = (initialValue, initialState) => {
  const [state, setState] = useState(initialState);
  const [opened, handlers] = useDisclosure(initialValue);

  const open = (newState) => {
    setState(newState);
    handlers.open();
  };
  const close = () => {
    setState();
    handlers.close();
  };

  const toggle = (newState) => {
    if (newState) {
      setState(newState);
    }
    handlers.toggle();
  };
  return [opened, { open, close, toggle, state }];
};

export const useQueryNotificationWrapper = ({ options = {}, errorMessage, successMessage }) => {
  const onSuccess = useCallback(
    (...args) => {
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        title: 'Success',
        message: successMessage ?? 'Updated successfully',
        color: 'emerald.5',
        icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
      });
      options.onSuccess?.(...args);
    },
    [successMessage, options],
  );

  const onError = useCallback(
    (...args) => {
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        title: 'Error',
        message: errorMessage ?? 'Something wen wrong, please try again later',
        color: 'red',
        icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
        loading: false,
      });
      options.onError?.(...args);
    },
    [errorMessage, options],
  );
  const getOptions = useCallback(() => {
    return { ...options, onError, onSuccess };
  }, [onError, onSuccess, options]);
  return getOptions;
};
