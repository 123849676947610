import { Paper, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconEdit } from '@tabler/icons-react';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { useInstitutions } from 'api/hooks';
import { useMemo } from 'react';

const getFormattedDate = (date) => {
  return date ? format(parseISO(date), 'dd-MM-yyyy hh:mm') : '';
};
export default function UserDetails({ user, onEditClick, visibilityClasses }) {
  const { t } = useTranslation();
  const { institutions } = useInstitutions({});
  const institution = useMemo(() => {
    return _.find(institutions, ['id', user?.institution]);
  }, [institutions, user]);
  return (
    <div className={`flex flex-col space-y-4 ${visibilityClasses}`}>
      <Paper withBorder p="md">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <span>{t('labels.last-name')}</span>
            <span className="text-md text-gray-500">{user?.lastName ?? '-'}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.first-name')}</span>
            <span className="text-md text-gray-500">{user?.firstName ?? '-'}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.email')}</span>
            <span className="text-md text-gray-500">{user?.email}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.institution-name')}</span>
            <span className="text-md text-gray-500">{institution?.name}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.created-at')}</span>
            <span className="text-md text-gray-500">{getFormattedDate(user?.createdAt)}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.status')}</span>
            <span className="text-md text-gray-500">{user?.active ? 'Activ' : 'Inactiv'}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span>{t('labels.roles')}</span>
            <span className="text-md text-gray-500">{_.join(_.map(user?.roles, 'code'), ', ')}</span>
          </div>
        </div>
      </Paper>
      <div className="w-full flex justify-end">
        <Button
          className="w-min mt-4"
          onClick={onEditClick}
          color="indigo.6"
          radius="xl"
          leftSection={<IconEdit className="p-0.5" />}
        >
          {t('btn.edit')}
        </Button>
      </div>
    </div>
  );
}
UserDetails.propTypes = {
  user: PropTypes.object,
  onEditClick: PropTypes.func,
  visibilityClasses: PropTypes.string,
};
UserDetails.defaultProps = {
  user: null,
  onEditClick: _.noop,
  visibilityClasses: '',
};
