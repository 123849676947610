/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Modal, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import FilePreview from './FilePreview';
import FileUploadForm from './FileUploadForm';

export default function FileModal({ opened, onClose, file, onSubmit, isSubmitting }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();

  const [visibleComponent, setVisibleComponent] = useState('details');

  const modalTitle = useMemo(() => {
    const formVisible = visibleComponent === 'form';
    if (formVisible) {
      return t('titles.edit');
    }
    return t('titles.details');
  }, [visibleComponent, t]);

  return (
    <Modal
      size="80%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent('details');
      }}
      centered
      keepMounted={false}
      title={modalTitle}
    >
      <div className="p-4">
        <VisibilityWrapper value={visibleComponent}>
          <FilePreview componentKey="details" file={file} onEditClick={() => setVisibleComponent('form')} />

          <FileUploadForm
            componentKey="form"
            onBackClick={() => setVisibleComponent('details')}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </VisibilityWrapper>
      </div>
    </Modal>
  );
}
FileModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  file: PropTypes.object,
};
FileModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  file: null,
};
