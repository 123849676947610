import _ from 'lodash';
import PropTypes from 'prop-types';
import { Select, Loader } from '@mantine/core';

const errorClasses =
  ' data-[error=true]:border-red-500  data-[error=true]:border-red-500 data-[error=true]:focus:border-red-500 data-[error=true]:focus:ring-red-500';
const focusColors = {
  indigo: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
  emerald: `border border-slate-300 shadow-sm focus:ring-1 focus:border-emerald-500 focus:ring-emerald-500  data-[error=true]:border-red-500 ${errorClasses}`,
};
export default function FieldSelect({ options, value, onChange, isLoading, focusColor, initialSelect, ...rest }) {
  const data = _.map(options, (item) => ({ value: item.code, label: item.name, item }));
  return (
    <Select
      classNames={{
        input: focusColors[focusColor],
      }}
      data={data}
      value={value?.value ?? null}
      onChange={onChange}
      radius="6px"
      rightSection={isLoading && <Loader size={20} color="zinc.2" />}
      disabled={isLoading}
      {...rest}
    />
  );
}
FieldSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  focusColor: PropTypes.string,
  initialSelect: PropTypes.bool,
};
FieldSelect.defaultProps = {
  value: null,
  onChange: _.noop,
  isLoading: false,
  focusColor: 'emerald',
  initialSelect: false,
};
