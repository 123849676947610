import PropTypes from 'prop-types';
import { Paper, Table, UnstyledButton, Collapse, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components/ui-messages';
import _ from 'lodash';

import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { usefulDoc } from 'api/constants';

export default function ContentTable({ data, file, onRowClick, onFileClick, isLoading }) {
  const { t } = useTranslation();
  const fileData = useMemo(() => {
    return file ? [{ key: 'Document', data: file, text: usefulDoc.name, isHtml: true }] : [];
  }, [file]);
  return (
    <Paper radius="8px" withBorder className="overflow-hidden flex flex-col">
      {isLoading && (
        <div className="flex justify-center items-center p-40">
          <Loader color="indigo.6" />
        </div>
      )}
      {_.map(_.keys(data), (dataKey) => {
        return (
          <TranslationGroup key={dataKey} resourceKey={dataKey} data={data?.[dataKey] ?? []} onRowClick={onRowClick} />
        );
      })}
      <TranslationGroup resourceKey="Documente" data={fileData} onRowClick={onFileClick} />
      {!isLoading && _.isEmpty(data) && <EmptyState message={t('messages.no-results')} className="w-full" />}
    </Paper>
  );
}

ContentTable.propTypes = {
  data: PropTypes.object,
  file: PropTypes.object,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  onFileClick: PropTypes.func,
};
ContentTable.defaultProps = {
  data: {},
  file: null,
  isLoading: false,
  onRowClick: _.noop,
  onFileClick: _.noop,
};

function TranslationGroup({ resourceKey, data, onRowClick }) {
  const [opened, { toggle }] = useDisclosure(true);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <UnstyledButton className="flex w-full items-center p-4 hover:bg-gray-100" onClick={toggle}>
        <span className="text-xl flex-grow text-gray-900 font-semibold">{resourceKey}</span>
        <div>
          <IconChevronDown
            className={classNames('size-4 transform transition-transform duration-300', { 'rotate-180': opened })}
          />
        </div>
      </UnstyledButton>
      <Collapse in={opened} className="p-4">
        <Paper radius="8px" withBorder className="overflow-hidden">
          <Table.ScrollContainer minWidth="70vw">
            <Table highlightOnHover classNames={{ th: 'font-medium py-3' }}>
              <Table.Thead className="bg-gray-50 text-gray-500 py-6">
                <Table.Tr>
                  <Table.Th>{t('tables.key')}</Table.Th>
                  <Table.Th>{t('tables.content')}</Table.Th>
                  <Table.Th>{t('tables.language')}</Table.Th>
                  <Table.Th>{t('tables.is-html')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {_.map(data, (item) => (
                  <Table.Tr
                    key={item.key}
                    className="text-gray-900 cursor-pointer"
                    onClick={() => {
                      onRowClick(item);
                    }}
                  >
                    <Table.Td className="text-sm font-base">
                      <span>{item.key ?? '-'}</span>
                    </Table.Td>
                    <Table.Td className="text-sm font-base">
                      <span>{item.text ?? '-'}</span>
                    </Table.Td>
                    <Table.Td className="text-sm font-base">
                      <span>{item.language ?? '-'}</span>
                    </Table.Td>
                    <Table.Td className="text-sm font-base">
                      <span>{item?.isHtml ? 'Da' : 'Nu'}</span>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Paper>
      </Collapse>
    </div>
  );
}

TranslationGroup.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  data: PropTypes.array,
};
TranslationGroup.defaultProps = {
  data: [],
  onRowClick: _.noop,
};
