import { useMemo } from 'react';
import _ from 'lodash';
import { useInstitutions } from 'api/hooks';
import { Select, Loader } from '@mantine/core';

const errorClasses =
  ' data-[error=true]:border-red-500  data-[error=true]:border-red-500 data-[error=true]:focus:border-red-500 data-[error=true]:focus:ring-red-500';

export default function InstitutionSelect({ ...rest }) {
  const { institutions, isLoading } = useInstitutions({});
  const options = useMemo(() => {
    return _.map(institutions, (institution) => ({
      value: `${institution.id}`,
      label: institution.name,
    }));
  }, [institutions]);
  return (
    <Select
      classNames={{
        input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
      }}
      rightSection={isLoading && <Loader size={20} color="zinc.2" />}
      data={options}
      searchable
      {...rest}
    />
  );
}
