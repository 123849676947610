/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Modal, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useGetNode } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import InstitutionForm from './InstitutionForm';
import InstitutionDetails from './InstitutionDetails';

export default function InstitutionModal({ opened, onClose, institution, onSubmit, isSubmitting }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();
  const isCreateMode = useMemo(() => !institution?.id, [institution]);
  const { node, isLoading } = useGetNode(institution?.id, { enabled: Boolean(institution?.id) });

  const initialVisibleComponent = useMemo(() => {
    return !institution?.id ? 'form' : 'details';
  }, [institution]);

  const [visibleComponent, setVisibleComponent] = useState(initialVisibleComponent);

  const modalTitle = useMemo(() => {
    const formVisible = visibleComponent === 'form';
    if (formVisible) {
      return isCreateMode ? t('titles.create-institution') : t('titles.edit-institution');
    }
    return t('titles.institution-details');
  }, [visibleComponent, isCreateMode, t]);

  useEffect(() => {
    setVisibleComponent(institution ? 'details' : 'form');
  }, [institution]);

  return (
    <Modal
      size="60%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent(initialVisibleComponent);
      }}
      centered
      keepMounted={false}
      title={modalTitle}
    >
      <div className="p-4">
        {isLoading && (
          <div className="flex justify-center items-center p-40">
            <Loader color="indigo.6" />
          </div>
        )}
        {!isLoading && (
          <VisibilityWrapper value={visibleComponent}>
            <InstitutionDetails
              componentKey="details"
              institution={{ ...(node ?? {}), fullAddress: institution?.address }}
              onEditClick={() => setVisibleComponent('form')}
            />
            <InstitutionForm
              componentKey="form"
              onBackClick={() => setVisibleComponent('details')}
              institution={node}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          </VisibilityWrapper>
        )}
      </div>
    </Modal>
  );
}
InstitutionModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  institution: PropTypes.object,
};
InstitutionModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  institution: null,
};
