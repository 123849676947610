import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import classNames from 'classnames';
import { IconCheck, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';

export default function PatientRights({ translationKey, itemsSize, className, bgColor }) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const BtnIcon = expanded ? IconChevronUp : IconChevronDown;

  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: `${translationKey}Title` });
  const { translation: description } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `${translationKey}Description`,
  });
  const { translation: translations } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: _.map(_.range(itemsSize), (i) => `${translationKey}Item[${i}]`),
  });
  // TODO: This is a temporary fix, investigate the issue with gradient background and refactor
  const gradient = useMemo(() => {
    return bgColor === 'slate' ? `bg-gradient-to-t from-slate-50` : 'bg-gradient-to-t from-white';
  }, [bgColor]);

  return (
    <div
      className={classNames(
        className,
        'flex flex-col items-center md:space-y-8 p-16',
        { 'bg-slate-50': bgColor === 'slate' },
        { 'bg-white': bgColor !== 'slate' },
      )}
    >
      <div className="flex md:w-2/3 text-center flex-col space-y-4">
        <span className="text-3xl font-extrabold text-zinc-900 text-wrap">{title?.text}</span>
        <span className="text-lg text-gray-500 font-normal">{description?.text}</span>
      </div>
      <div className="flex flex-col w-full mt-8 items-center flex-center">
        <div
          className={classNames(
            'relative flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 transition-max-height duration-500 ease-in-out overflow-hidden max-w-full w-max',
            {
              'max-h-full': expanded,
              'max-h-72': !expanded,
            },
          )}
        >
          {translations?.map((item) => (
            <CheckmarkLabel key={item.key} title={item?.text} />
          ))}
          <div
            className={classNames(`${gradient} absolute bottom-0 h-28 w-full  transition-opacity duration-500`, {
              'opacity-0': expanded,
            })}
          />
        </div>
        <div className="flex items-center justify-center md:py-14 py-10 bg-transparent">
          <Button
            radius="xl"
            onClick={() => setExpanded((prev) => !prev)}
            rightSection={<BtnIcon className="size-5" />}
          >
            {expanded ? t('btn.learn-less') : t('btn.read-more')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function CheckmarkLabel({ title }) {
  return (
    <div className="flex space-x-4 space-between">
      <IconCheck className="size-6 text-emerald-500 flex-none" />
      <div className="flex flex-col space-y-2">
        <span className="text-lg font-base text-zinc-900">{title}</span>
      </div>
    </div>
  );
}
CheckmarkLabel.propTypes = {
  title: PropTypes.string.isRequired,
};
PatientRights.propTypes = {
  translationKey: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  itemsSize: PropTypes.number,
};
PatientRights.defaultProps = {
  bgColor: 'slate',
  className: '',
  itemsSize: 0,
};
