import { FieldSelect } from 'components/form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useDictionary } from 'api/hooks';

export default function GenderFilter({ onChange, ...rest }) {
  const { dictionary, isLoading } = useDictionary('Sex');
  const [selectedValue, setSelectedValue] = useState();
  const onSelect = useCallback(
    (option) => {
      setSelectedValue(option);
      onChange(option);
    },
    [onChange],
  );
  return (
    <FieldSelect
      value={selectedValue}
      onChange={(_value, option) => onSelect(option)}
      options={dictionary ?? []}
      focusColor="indigo"
      isLoading={isLoading}
      {...rest}
    />
  );
}

GenderFilter.propTypes = {
  onChange: PropTypes.func,
};
GenderFilter.defaultProps = {
  onChange: _.noop,
};
