import { Children, cloneElement } from 'react';

export default function VisibilityWrapper({ value, children }) {
  const mapped = Children.map(children, (child) => {
    const animationClasses =
      child.props.componentKey === value ? 'animate-fade' : 'animate-fade animate-reverse hidden';
    return cloneElement(child, { ...child.props, visibilityClasses: animationClasses });
  });
  return Children.toArray(mapped).filter((c) => c.props.componentKey === value);
  // return Children.map(children, (child) => {
  //   const animationClasses =
  //     child.props.componentKey === value ? 'animate-fade' : 'animate-fade animate-reverse hidden';
  //   return cloneElement(child, { ...child.props, visibilityClasses: animationClasses });
  // });
}
