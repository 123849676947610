import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton } from '@mantine/core';
import { DonutChart } from '@mantine/charts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ChartTitle, StatsLabelValue } from './components';

export default function HospitalizationMethodStats({ title, queryKey, filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey,
    ...filter,
  });
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const colors = [
        { color: 'indigo.5', legendColor: 'bg-indigo-500' },
        { color: 'yellow.4', legendColor: 'bg-yellow-400' },
      ];
      return _.map(stats, (item, index) => {
        return { name: item.mainName, value: item.total, ...colors[index] };
      });
    }
    return [];
  }, [stats]);
  // const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  //   const RADIAN = Math.PI / 180;

  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={title} />
          <div className="flex flex-col justify-center items-center">
            <DonutChart data={chartData} size={200} thickness={50} />
            {withLegend && (
              <div className="flex flex-col space-y-2 mt-8">
                <StatsLabelValue label={t('stats.total')} value={stats?.satisfactionStats?.total} />
                {_.map(chartData, (item) => {
                  return (
                    <div className="flex space-x-2 items-center" key={item.name}>
                      <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                      <StatsLabelValue label={item.name} value={item.value} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Skeleton>
  );
}

HospitalizationMethodStats.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withLegend: PropTypes.bool,
};

HospitalizationMethodStats.defaultProps = {
  filter: {},
  withLegend: false,
};
