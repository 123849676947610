import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import locales from './locales';

const { ro, ru } = locales;
i18n.use(initReactI18next).init({
  fallbackLng: 'ro',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ro: { translation: ro.translation },
    ru: { translation: ru.translation },
  },
});

export default i18n;
