/* eslint-disable no-unused-vars */
import { Radio, Group } from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function RadioGroup({ options, value, error, onChange, ...rest }) {
  return (
    <Radio.Group error={error} errorProps={{ mt: '4px' }} value={value} onChange={onChange} {...rest}>
      <Group>
        {_.map(options, (item) => {
          const checked = item.code === value;
          return (
            <Radio
              color="emerald.5"
              key={item.code}
              value={item.code}
              label={item.name}
              className={classNames(`px-4 py-3 rounded-md flex-none w-max`, {
                'bg-emerald-50 text-emerald-900': checked,
                'bg-slate-100 text-slate-900': !checked,
              })}
            />
          );
        })}
      </Group>
    </Radio.Group>
  );
}

RadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  value: '',
  error: '',
  onChange: _.noop,
};
