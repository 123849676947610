import { ActionIcon } from '@mantine/core';
import { IconPlay } from 'components/icons';
import classNames from 'classnames';
import ReactPlayer from 'react-player/youtube';
import { useGetPublicTranslation } from 'api/hooks';

export default function VideoIntroductionContainer() {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'VideoExplanationTitle' });
  const { translation: description } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: 'VideoExplanationDescription',
  });
  return (
    <div className={classNames('flex flex-col  items-center justify-center bg-white py-14 space-y-14')}>
      <div className="flex flex-col items-center justify-center text-center space-y-6 md:w-2/3">
        <span className="text-4xl font-bold text-zinc-900 w-2/3">{title?.text}</span>
        <span className="text-lg font-base text-zinc-600">{description?.text}</span>
      </div>
      <div className="rounded-lg shadow-md  w-full max-w-[933px] h-[426px]  overflow-hidden">
        <ReactPlayer
          width="100%"
          height="100%"
          light
          url="https://www.youtube.com/watch?v=ggNCn2WpL9E"
          playIcon={
            <ActionIcon className="size-[7.5rem] rounded-full border border-white " color="slate.5">
              <IconPlay className="text-white size-14" />
            </ActionIcon>
          }
        />
      </div>
    </div>
  );
}
