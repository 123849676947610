export const queryKeys = {
  questionnaire: (id) => ['questionnaire', id],
  locations: ({ locale = 'ro' }) => ['locations', locale],
  institutions: ({ locationCode, locale = 'ro' }) => ['institutions', locationCode, locale],
  institutionCategories: ({ locale = 'ro' }) => ['institutionCategories', locale],
  institutionSections: ({ locale = 'ro' }) => ['institutionSections', locale],
  ageGroups: ({ locale = 'ro' }) => ['ageGroups', locale],
  dashboardTrends: (params) => ['dashboardTrends', params],
  dashboardStatsData: (params) => ['dashboardStatsData', params],
  reportsTrends: (params) => ['reportsTrends', params],
  reports: (params) => ['reports', params],
  report: (id) => ['report', id],
  generalImpressions: () => ['generalImpressions'],
  user: (id) => ['user', id],
  loggedInUser: (id) => ['loggedInUser', id],
  users: (params) => ['users', params],
  dashboardStats: (statsKey, period, institution) => ['dashboardStats', institution, statsKey, period],
  roles: () => ['rolesList'],
  nodes: (params) => ['nodes', params],
  node: (id) => ['node', id],
  questionnaires: (params) => ['questionnaires', params],
  questionnaireMetadata: (id) => ['questionnaireMetadata', id],
  dictionary: (dictionary) => ['dictionary', dictionary],
  dictionaries: () => ['dictionaries'],
  translations: () => ['translations'],
  multipleTranslations: (keys) => ['translations', ...keys],
  translation: (resourceKey, key) => ['translation', resourceKey, key],
  publicTranslation: () => ['translation', 'public'],
  file: (fileName) => ['file', fileName],
};
