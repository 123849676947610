import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton } from '@mantine/core';
import { DonutChart } from '@mantine/charts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { dashboardKeys } from 'api/constants';
import { ChartTitle, StatsLabelValue } from './components';

const impressions = {
  satisfied: {
    code: '1',
    name: 'satisfied',
  },
  neutral: {
    code: '0.5',
    name: 'neutral',
  },
  dissatisfied: {
    code: '0',
    name: 'dissatisfied',
  },
};
export default function PatientSatisfactionStats({ filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey: dashboardKeys.patientSatisfactionStats,
    ...filter,
  });
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const satisfied = _.find(stats, (item) => item.mainCode === impressions.satisfied.code);
      const partialSatisfied = _.find(stats, (item) => item.mainCode === impressions.neutral.code);
      const dissatisfied = _.find(stats, (item) => item.mainCode === impressions.dissatisfied.code);

      const data = [
        {
          name: t('stats.satisfied'),
          value: satisfied?.total ?? 0,
          color: 'emerald.5',
          legendColor: 'bg-emerald-500',
        },
        {
          name: t('stats.partial-satisfied'),
          value: partialSatisfied?.total ?? 0,
          color: 'indigo.5',
          legendColor: 'bg-indigo-500',
        },
        {
          name: t('stats.dissatisfied'),
          value: dissatisfied?.total ?? 0,
          color: 'yellow.4',
          legendColor: 'bg-yellow-400',
        },
      ];

      return data;
    }
    return [];
  }, [stats, t]);
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={t('stats.titles.patients-satisfaction')} />
          <div className="flex flex-col justify-center items-center">
            <DonutChart data={chartData} size={200} thickness={40} />
            {withLegend && (
              <div className="flex flex-col space-y-2 mt-8">
                <StatsLabelValue label={t('stats.total')} value={stats?.satisfactionStats?.total} />
                {_.map(chartData, (item) => {
                  return (
                    <div className="flex space-x-2 items-center" key={item.name}>
                      <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                      <StatsLabelValue label={item.name} value={item.value} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Skeleton>
  );
}
PatientSatisfactionStats.propTypes = {
  filter: PropTypes.object,
  withLegend: PropTypes.bool,
};
PatientSatisfactionStats.defaultProps = {
  filter: {},
  withLegend: false,
};
