import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { BrowserRouter } from 'react-router-dom';
import LocaleProvider from 'locale/LocaleProvider';
import resolveConfig from 'tailwindcss/resolveConfig';
import _ from 'lodash';
import { AuthProvider } from 'auth';
import tailwindConfig from './tailwind.config';
import App from './App';
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/dropzone/styles.css';

const fullConfig = resolveConfig(tailwindConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const convertColors = (colors) => {
  const color = {};
  _.forEach(
    _.filter(_.keys(colors), (c) => !_.includes(['inherit', 'current', 'transparent'], c)),
    (key) => {
      const colorValue = colors[key];
      color[key] = _.isObject(colorValue) ? _.values(colors[key]) : [colorValue];
    },
  );
  return color;
};
const twColors = convertColors(fullConfig.theme?.colors);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        colors: twColors,
        defaultRadius: '6px',
        fontFamily: 'Onest, sans-serif',
        // components: {
        //   Input: {
        //     styles: () => ({
        //       input: {
        //         borderColor: fullConfig.theme?.colors.gray['300'],
        //         '&:focus': {
        //           borderColor: 'red',
        //         },
        //       },
        //     }),
        //   },
        // },
      }}
    >
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </LocaleProvider>
      </QueryClientProvider>
    </MantineProvider>
  </React.StrictMode>,
);
