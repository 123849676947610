import { useForm, hasLength, isNotEmpty } from '@mantine/form';
import { Button, Group, PasswordInput } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMemo } from 'react';

export default function UserPasswordForm({
  withOldPassword,
  user,
  onSubmit,
  isSubmitting,
  onClose,
  visibilityClasses,
}) {
  const { t } = useTranslation();
  const validations = useMemo(() => {
    const validate = {
      password: hasLength({ min: 6, max: 50 }, 'Parola trebuie să conțină 6 - 50 caractere'),
      confirmPassword: (value, values) => (value !== values.password ? 'Parolele nu coincid' : null),
    };
    if (withOldPassword) {
      validate.oldPassword = isNotEmpty('Introduceți parola curentă');
    }
    return validate;
  }, [withOldPassword]);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      password: '',
      confirmPassword: '',
      userId: user?.id,
    },
    validate: validations,
    transformValues: (values) => (withOldPassword ? _.omit(values, ['userId']) : values),
  });

  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        {withOldPassword && (
          <PasswordInput
            placeholder={t('placeholders.current-password')}
            label={t('labels.current-password')}
            required
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            {...form.getInputProps('oldPassword')}
          />
        )}
        <PasswordInput
          className="mt-4"
          placeholder={t('placeholders.password')}
          label={t('labels.password')}
          required
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          {...form.getInputProps('password')}
        />
        <PasswordInput
          className="mt-4"
          placeholder={t('labels.confirm-password')}
          label={t('labels.confirm-password')}
          required
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          {...form.getInputProps('confirmPassword')}
        />

        <Group justify="space-between" className="mt-7">
          <Button
            variant="outline"
            leftSection={<IconArrowLeft size={16} />}
            radius="xl"
            color="gray.5"
            onClick={onClose}
          >
            {t('btn.back')}
          </Button>
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
UserPasswordForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
  withOldPassword: PropTypes.bool,
};
UserPasswordForm.defaultProps = {
  user: null,
  onSubmit: _.noop,
  onClose: _.noop,
  isSubmitting: false,
  visibilityClasses: '',
  withOldPassword: false,
};
