import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AppLogoDomain } from 'components/icons';
import { AuthForm, ForgotPasswordForm } from 'components/form';
import { useCallback, useState } from 'react';
// import { useAuthContext } from 'auth';

export default function AuthModal({ opened, close, login, isLoginLoading }) {
  const [modalContent, setModalContent] = useState('login');

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        username: values.username,
        password: values.password,
      };
      login(payload);
    },
    [login],
  );

  const onForgotPasswordSubmit = useCallback((values) => {
    console.log('Forgot Values: ', values);
  }, []);

  const onForgotClick = useCallback(() => {
    setModalContent('forgot');
  }, []);
  const onBackClick = useCallback(() => {
    setModalContent('login');
  }, []);
  const onModalClose = useCallback(() => {
    setModalContent('login');
    close();
  }, [close]);
  return (
    <Modal
      opened={opened}
      onClose={onModalClose}
      centered
      size="auto"
      classNames={{ content: 'p-7' }}
      radius="16px"
      overlayProps={{
        backgroundOpacity: 0.75,
        blur: 10,
      }}
    >
      <div className="flex flex-col  justify-center items-center">
        <AppLogoDomain />
        <AuthForm
          onSubmit={onSubmit}
          onForgotClick={onForgotClick}
          isVisible={modalContent === 'login'}
          isLoading={isLoginLoading}
        />
        <ForgotPasswordForm
          onSubmit={onForgotPasswordSubmit}
          onBackClick={onBackClick}
          isVisible={modalContent === 'forgot'}
        />
      </div>
    </Modal>
  );
}

AuthModal.propTypes = {
  opened: PropTypes.bool,
  close: PropTypes.func,
  login: PropTypes.func,
  isLoginLoading: PropTypes.bool,
};
AuthModal.defaultProps = {
  opened: false,
  close: _.noop,
  login: _.noop,
  isLoginLoading: false,
};
