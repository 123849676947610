import { useEffect } from 'react';
import { Button, QuestionnaireSection } from 'components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { RadioGroup, FieldSelect } from 'components/form';
import { useQuestionnaireContext } from 'modules/questionnaire/hooks/useQuestionnaireContext';

const localesFields = [
  {
    code: 'ro',
    name: 'Română',
  },
  {
    code: 'ru',
    name: 'Rusă',
  },
];
export default function QuestionnaireSetup({ onNext, style, isVisible }) {
  const { t } = useTranslation();
  const animationClasses = isVisible ? 'animate-fade-right' : 'animate-fade-left animate-reverse hidden';

  const {
    locations,
    institutions,
    isLoadingLocations,
    isLoadingInstitutions,
    localeConfig,
    locationConfig,
    institutionConfig,
    setSubmittedSuccessfully,
  } = useQuestionnaireContext();

  useEffect(() => {
    setSubmittedSuccessfully(false);
  }, [setSubmittedSuccessfully]);
  const canStartQuestionnaire = Boolean(localeConfig?.locale) && Boolean(institutionConfig?.institution?.value);

  return (
    <div style={style} className={`${animationClasses} w-full rounded-xl shadow-xl bg-white flex flex-col space-y-2`}>
      <div className="p-8">
        <div className="flex flex-col flex-grow space-y-8">
          <div className="flex flex-col space-y-2">
            <div className="space-y-1 flex flex-col mb-1">
              <span className="font-semibold text-grey-700 text-base">{t('forms.select-locale')}</span>
              <span className="text-slate-500 text-sm">{t('forms.select-locale-description')}</span>
            </div>
            <RadioGroup options={localesFields} value={localeConfig.locale} onChange={localeConfig.setLocale} />
          </div>

          <div className="flex flex-col space-y-2">
            <div className="space-y-1 flex flex-col mb-1">
              <span className="font-semibold text-grey-700 text-base">{t('forms.select-location')}</span>
            </div>
            <div className="sm:w-2/3 w-full">
              <FieldSelect
                options={locations ?? []}
                placeholder={t('forms.select-location')}
                value={locationConfig.location}
                onChange={(_value, option) => locationConfig.setLocation(option)}
                isLoading={isLoadingLocations}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="space-y-1 flex flex-col mb-1">
              <span className="font-semibold text-grey-700 text-base">{t('forms.select-institution')}</span>
            </div>
            <div className="sm:w-2/3 w-full">
              <FieldSelect
                options={institutions ?? []}
                placeholder={t('forms.select-institution')}
                value={institutionConfig.institution}
                onChange={(_value, option) => institutionConfig.setInstitution(option)}
                isLoading={isLoadingInstitutions}
              />
            </div>
          </div>
        </div>
      </div>
      <QuestionnaireSection.Footer className={classNames('justify-end')}>
        <Button onClick={onNext} color="primary" radius="xl" disabled={!canStartQuestionnaire}>
          {t('btn.confirm')}
        </Button>
      </QuestionnaireSection.Footer>
    </div>
  );
}

QuestionnaireSetup.propTypes = {
  onNext: PropTypes.func,
  style: PropTypes.object,
  isVisible: PropTypes.bool,
};
QuestionnaireSetup.defaultProps = {
  onNext: _.noop,
  style: {},
  isVisible: true,
};
