import ro from './ro/translation.json';
import ru from './ru/translation.json';

const locales = {
  ro: {
    code: 1,
    name: 'Română',
    codeName: 'ro_MD',
    translation: ro,
    shortName: 'Ro',
    key: 'ro',
  },
  ru: {
    code: 2,
    name: 'Русский',
    codeName: 'ru_MD',
    translation: ru,
    shortName: 'Ru',
    key: 'ru',
  },
};
export default locales;
