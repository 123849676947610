import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const getObjectValue = (v) => {
  if (_.isArray(v)) {
    const array = _.map(v, (i) => i.code);
    return _.isEmpty(array) ? '' : `[${array}]`;
  }
  if (_.isObject(v)) {
    return v.value ?? v.code;
  }
  return v;
};
const parseFields = (section) => {
  return _.map(_.keys(section), (k) => ({ Code: k, value: getObjectValue(section[k]) }));
};
export const parseSectionsDto = (values) => {
  return _.map(_.keys(values), (key) => {
    const section = values[key];
    return {
      Code: key,
      Fields: parseFields(section),
    };
  });
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('vpudid');
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem('vpudid', deviceId);
  }
  return deviceId;
};
