import PropTypes from 'prop-types';
import { Group } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import Button from 'components/button/Button';
import { IconArrowLeft } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import TextField from './TextField/TextField';

export default function ForgotPasswordForm({ onSubmit, onBackClick, isVisible }) {
  const { t } = useTranslation();
  const animationClasses = isVisible ? 'animate-fade-left' : 'animate-fade-right animate-reverse hidden';

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isEmail(''),
    },
  });

  return (
    <div className={`${animationClasses} flex flex-col space-y-6 p-3 w-[448px]`}>
      <span className="text-3xl font-extrabold text-center text-gray-900">Recuperează parola</span>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextField
          radius="0"
          variant="unstyled"
          classNames={{
            input: 'px-3 border border-slate-300 rounded-md data-[error=true]:border-red-500',
          }}
          placeholder="Email address"
          {...form.getInputProps('email')}
        />

        <Group justify="space-between" className="mt-6">
          <Button
            onClick={onBackClick}
            color="default"
            variant="default"
            leftSection={<IconArrowLeft size={20} />}
            radius="xl"
          >
            {t('btn.back')}
          </Button>
          <Button radius="xl" color="gradient" type="submit">
            Recuperează parola
          </Button>
        </Group>
      </form>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};
ForgotPasswordForm.defaultProps = {
  isVisible: false,
};
