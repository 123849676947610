import { useMemo } from 'react';
import _ from 'lodash';
import { useRoles } from 'api/hooks';
import { MultiSelect, Loader } from '@mantine/core';

const errorClasses =
  ' data-[error=true]:border-red-500  data-[error=true]:border-red-500 data-[error=true]:focus:border-red-500 data-[error=true]:focus:ring-red-500';

export default function RolesSelect({ ...rest }) {
  const { roles, isLoading } = useRoles();

  const options = useMemo(() => {
    return _.map(roles?.data, (role) => ({
      value: role.code,
      label: role.name,
    }));
  }, [roles?.data]);
  return (
    <MultiSelect
      classNames={{
        input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
      }}
      rightSection={isLoading && <Loader size={20} color="zinc.2" />}
      data={options}
      {...rest}
    />
  );
}
