/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, QuestionnaireSection } from 'components';
import { QuestionnaireSubmitted } from 'components/questionnaire';
import { LoadingOverlay } from '@mantine/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useWindowScroll } from '@mantine/hooks';
import { getObjectValue } from 'utils';
import { useTranslation } from 'react-i18next';
import { useQuestionnaireContext } from './hooks/useQuestionnaireContext';

export default function Questionnaire() {
  const {
    questionnaire,
    isLoading,
    fetchQuestionnaire,
    localeConfig,
    institutionConfig,
    submitQuestionnaire,
    isSubmitting,
    isSubmittedSuccessfully,
  } = useQuestionnaireContext();
  const totalSections = _.size(questionnaire?.sections);
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState(questionnaire?.sections?.[0]);
  const navigate = useNavigate();
  const requiredFields = useMemo(() => {
    return _.filter(currentSection?.fields, (field) => field.isRequired);
  }, [currentSection]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [scroll, scrollTo] = useWindowScroll();

  const onValuesChanged = useCallback(({ sectionKey, field, value }) => {
    setValues((prev) => {
      const sectionValues = _.cloneDeep(_.get(prev, sectionKey, {}));
      sectionValues[field.code] = value;
      return { ...prev, [sectionKey]: sectionValues };
    });
  }, []);

  const scrollToTop = () => {
    if (scroll.y !== 0) {
      scrollTo({ y: 0 });
    }
  };
  useEffect(() => {
    if (!localeConfig?.locale || !institutionConfig?.institution) {
      navigate('/questionnaire');
    }
  }, [localeConfig, institutionConfig, navigate]);

  useEffect(() => {
    fetchQuestionnaire();
  }, [fetchQuestionnaire]);

  useEffect(() => {
    if (!currentSection) {
      setCurrentSection(questionnaire?.sections?.[0]);
    }
  }, [questionnaire?.sections, currentSection]);

  const onNextClick = (sectionValues) => {
    const unfilledRequiredFields = _.filter(requiredFields, (field) => {
      const value = getObjectValue(sectionValues[field.code]);
      const isValid = !_.isNil(value) && !_.isEmpty(value);
      return !isValid;
    });
    const isSectionValid = _.isEmpty(unfilledRequiredFields);
    setErrors((prev) => {
      const sectionErrors = _.cloneDeep(_.get(prev, currentSection.code, {}));
      _.forEach(unfilledRequiredFields, (item) => {
        sectionErrors[item.code] = t('messages.required-field');
      });
      return { ...prev, [currentSection.code]: isSectionValid ? {} : sectionErrors };
    });
    if (isSectionValid) {
      setCurrentSection((prev) => {
        const currentIndex = _.indexOf(questionnaire?.sections, prev);
        return _.get(questionnaire, `sections[${currentIndex + 1}]`, currentSection);
      });
      scrollToTop();
    }
  };
  const onPreviousClick = () => {
    setCurrentSection((prev) => {
      const currentIndex = _.indexOf(questionnaire?.sections, prev);
      return _.get(questionnaire, `sections[${currentIndex - 1}]`, currentSection);
    });
    scrollToTop();
  };
  const onSubmit = () => {
    submitQuestionnaire(values);
  };
  return (
    <Layout>
      <div className="flex flex-col justify-center items-center h-full">
        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
        {currentSection && !isSubmittedSuccessfully && (
          <QuestionnaireSection
            section={currentSection}
            totalSections={totalSections}
            sectionIndex={_.indexOf(questionnaire?.sections, currentSection)}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            values={values?.[currentSection.code] ?? {}}
            errors={errors?.[currentSection.code] ?? {}}
            onValuesChanged={onValuesChanged}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}
        {isSubmittedSuccessfully && <QuestionnaireSubmitted />}
      </div>
    </Layout>
  );
}
