/* eslint-disable no-unused-vars */
import { useMutation } from 'react-query';
import { usefulDoc } from 'api/constants';
import { useQueryNotificationWrapper } from 'utils';
import { api } from '../api';

export const useSubmitQuestionnaire = (options) => {
  const { mutate: submitQuestionnaire, ...rest } = useMutation(
    async ({ id, payload }) => api.submitQuestionnaire({ id, payload }),
    options,
  );
  return { submitQuestionnaire, ...rest };
};

export const useAuth = (options) => {
  const { mutate: login, ...rest } = useMutation(async (payload) => api.login(payload), options);
  return { login, ...rest };
};

export const useExportReports = (options) => {
  const { mutate: exportReports, ...rest } = useMutation(
    async ({ type, page, params }) => api.exportReports({ type, page, params }),
    options,
  );
  return { exportReports, ...rest };
};

export const useCreateUser = (options) => {
  const getOptions = useQueryNotificationWrapper({ options, successMessage: 'Crated Successfully' });

  const { mutate: createUser, ...rest } = useMutation(async (payload) => api.createUser({ payload }), getOptions());
  return { createUser, ...rest };
};

export const useUpdateUser = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });

  const { mutate: updateUser, ...rest } = useMutation(
    async ({ id, payload }) => api.updateUser({ id, payload }),
    getOptions(),
  );
  return { updateUser, ...rest };
};

export const useSetUserPassword = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });

  const { mutate: setUserPassword, ...rest } = useMutation(
    async ({ id, payload }) => api.setUserPassword({ id, payload }),
    getOptions(),
  );
  return { setUserPassword, ...rest };
};

export const useCreateNode = (options) => {
  const getOptions = useQueryNotificationWrapper({ options, successMessage: 'Crated Successfully' });

  const { mutate: createNode, ...rest } = useMutation(async (payload) => api.createNode({ payload }), getOptions());
  return { createNode, ...rest };
};

export const useUpdateNode = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });

  const { mutate: updateNode, ...rest } = useMutation(
    async ({ id, payload }) => api.updateNode({ id, payload }),
    getOptions(),
  );
  return { updateNode, ...rest };
};

export const useCreateQuestionnaire = (options) => {
  const getOptions = useQueryNotificationWrapper({ options, successMessage: 'Crated Successfully' });

  const { mutate: createQuestionnaire, ...rest } = useMutation(
    async (payload) => api.createQuestionnaire({ payload }),
    getOptions(),
  );
  return { createQuestionnaire, ...rest };
};

export const useUpdateQuestionnaire = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });
  const { mutate: updateQuestionnaire, ...rest } = useMutation(
    async ({ id, payload }) => api.updateQuestionnaire({ id, payload }),
    getOptions(),
  );
  return { updateQuestionnaire, ...rest };
};

export const useDownloadDocument = (options) => {
  const { mutate: downloadDocument, ...rest } = useMutation(
    async () => api.downloadFile({ fileName: usefulDoc.name }),
    options,
  );
  return { downloadDocument, ...rest };
};

export const useDownloadFile = (options) => {
  const { mutate: downloadFile, ...rest } = useMutation(async (filename) => api.downloadFile({ filename }), options);
  return { downloadFile, ...rest };
};
export const useUpdateTranslation = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });

  const { mutate: updateTranslation, ...rest } = useMutation(
    async ({ resourceKey, key, payload }) => api.updateTranslation({ resourceKey, key, payload }),
    getOptions(),
  );
  return { updateTranslation, ...rest };
};

export const useUploadFile = (options) => {
  const getOptions = useQueryNotificationWrapper({ options, successMessage: 'File uploaded successfully' });
  const { mutate: uploadFile, ...rest } = useMutation(async (file) => api.uploadFile({ payload: file }), getOptions());
  return { uploadFile, ...rest };
};

export const useUpdateCurrentUser = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });

  const { mutate: updateUser, ...rest } = useMutation(
    async (payload) => api.updateCurrentUser({ payload }),
    getOptions(),
  );
  return { updateUser, ...rest };
};

export const useUpdateUserPassword = (options) => {
  const getOptions = useQueryNotificationWrapper({ options });
  const { mutate: updateUserPassword, ...rest } = useMutation(
    async (payload) => api.updateCurrentUserPassword({ payload }),
    getOptions(),
  );
  return { updateUserPassword, ...rest };
};
