import { useForm, isEmail, hasLength, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput } from '@mantine/core';
import { LocationSelect } from 'components/fields';
import { IconArrowLeft } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default function InstitutionForm({ institution, onSubmit, isSubmitting, onBackClick, visibilityClasses }) {
  const { t } = useTranslation();

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      parent: institution?.parent ?? 0,
      code: institution?.code ?? '',
      name: institution?.name ?? '',
      phone: institution?.phone ?? '',
      fax: institution?.fax ?? '',
      eMail: institution?.eMail ?? '',
      address: {
        locality: _.toString(institution?.address?.locality),
        street: institution?.address?.street ?? '',
        house: institution?.address?.house ?? '',
        apt: institution?.address?.apt ?? '',
      },
    },
    validate: {
      name: hasLength({ min: 3, max: 50 }, 'Institution Name must be 3-10 characters long'),
      eMail: isEmail('Invalid email'),
      'address.locality': isNotEmpty('Field is required'),
      'address.street': isNotEmpty('Field is required'),
    },
  });

  const isCreateMode = useMemo(() => !institution, [institution]);
  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.institution-name')}
          placeholder={t('labels.institution-name')}
          withAsterisk
          {...form.getInputProps('name')}
        />
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.phone')}
          placeholder={t('labels.phone')}
          mt="md"
          {...form.getInputProps('phone')}
        />
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.fax')}
          placeholder={t('labels.fax')}
          mt="md"
          {...form.getInputProps('fax')}
        />
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.email')}
          placeholder={t('labels.email')}
          withAsterisk
          mt="md"
          {...form.getInputProps('eMail')}
        />
        <LocationSelect
          className="mt-4"
          withAsterisk
          label={t('labels.location')}
          {...form.getInputProps('address.locality')}
          placeholder={t('placeholders.location-select')}
        />

        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          withAsterisk
          label={t('labels.street')}
          placeholder={t('labels.street')}
          mt="md"
          {...form.getInputProps('address.street')}
        />
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.house')}
          placeholder={t('labels.house')}
          mt="md"
          {...form.getInputProps('address.house')}
        />
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.apartment')}
          placeholder={t('labels.apartment')}
          mt="md"
          {...form.getInputProps('address.apt')}
        />
        <Group justify={isCreateMode ? 'flex-end' : 'space-between'} className="mt-7">
          {!isCreateMode && (
            <Button
              variant="outline"
              leftSection={<IconArrowLeft size={16} />}
              radius="xl"
              color="gray.5"
              onClick={onBackClick}
            >
              {t('btn.back')}
            </Button>
          )}
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
InstitutionForm.propTypes = {
  institution: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
};
InstitutionForm.defaultProps = {
  institution: null,
  onSubmit: _.noop,
  onBackClick: _.noop,
  isSubmitting: false,
  visibilityClasses: '',
};
