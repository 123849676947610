/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { Paper, Button, Anchor, Group, Table } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconEdit } from '@tabler/icons-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { usefulDoc } from 'api/constants';

export default function FilePreview({ file, onEditClick, visibilityClasses }) {
  const { t } = useTranslation();

  const fileUrl = useMemo(() => {
    if (file?.data?.data) {
      const binaryString = window.atob(file.data.data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const pdfBlob = new Blob([bytes], { type: 'application/pdf' });
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      return pdfBlobUrl;
    }

    return null;
  }, [file]);

  return (
    <div className={`flex flex-col h-full space-y-4 ${visibilityClasses}`}>
      <Paper radius="8px" withBorder className="overflow-hidden h-[70vh]">
        {fileUrl && (
          <iframe
            src={fileUrl}
            width="100%"
            height="100%"
            title={usefulDoc.title}
            style={{ border: 'none', height: '100%' }}
          />
        )}
      </Paper>

      <div className="w-full flex justify-end">
        <Button
          className="w-min mt-4"
          onClick={onEditClick}
          color="indigo.6"
          radius="xl"
          leftSection={<IconEdit className="p-0.5" />}
        >
          {t('btn.edit')}
        </Button>
      </div>
    </div>
  );
}

FilePreview.propTypes = {
  file: PropTypes.object,
  onEditClick: PropTypes.func,
  visibilityClasses: PropTypes.string,
};
FilePreview.defaultProps = {
  onEditClick: _.noop,
  visibilityClasses: '',
  file: null,
};
