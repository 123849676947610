import { useMemo } from 'react';
import _ from 'lodash';
import { useLocations } from 'api/hooks';
import { Select, Loader } from '@mantine/core';

const errorClasses =
  ' data-[error=true]:border-red-500  data-[error=true]:border-red-500 data-[error=true]:focus:border-red-500 data-[error=true]:focus:ring-red-500';

export default function LocationSelect({ ...rest }) {
  const { locations, isLoading } = useLocations({});
  const options = useMemo(() => {
    return _.map(locations, (location) => ({
      value: `${location.id}`,
      label: location.name,
    }));
  }, [locations]);
  return (
    <Select
      classNames={{
        input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
      }}
      rightSection={isLoading && <Loader size={20} color="zinc.2" />}
      data={options}
      {...rest}
    />
  );
}
