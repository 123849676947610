const keys = {
  token: 'vpact',
};

export const getToken = () => {
  const token = localStorage.getItem(keys.token);
  return token;
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(keys.token, token);
  }
};

export const clearTokenData = () => {
  localStorage.removeItem(keys.token);
};
