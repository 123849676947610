import { useState } from 'react';
import { Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import ProfileForm from './ProfileForm';
import UserPasswordForm from '../users/UserPasswordForm';

export default function ProfileModal({ opened, onClose, user, onSubmit, isSubmitting, onPasswordChange }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();

  const [visibleComponent, setVisibleComponent] = useState('form');

  return (
    <Modal
      size="60%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent('form');
      }}
      centered
      keepMounted={false}
      title={t('titles.edit-user')}
    >
      <VisibilityWrapper value={visibleComponent}>
        <ProfileForm
          user={user}
          componentKey="form"
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          onChangePasswordClick={() => setVisibleComponent('passwordForm')}
        />
        <UserPasswordForm
          user={user}
          componentKey="passwordForm"
          onClose={() => setVisibleComponent('form')}
          onSubmit={onPasswordChange}
          isSubmitting={isSubmitting}
          withOldPassword
        />
      </VisibilityWrapper>
    </Modal>
  );
}

ProfileModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPasswordChange: PropTypes.func,
  user: PropTypes.object,
};

ProfileModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  onPasswordChange: _.noop,
  user: null,
};
