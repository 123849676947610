/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldSelect } from 'components/form';
import { useInstitutions } from 'api/hooks';

export default function InstitutionFilter({ onChange, location, ...rest }) {
  const { institutions, isLoading } = useInstitutions({ locationCode: location });
  const [selectedValue, setSelectedValue] = useState();

  const onSelect = useCallback(
    (option) => {
      setSelectedValue(option);
      onChange(option);
    },
    [onChange],
  );
  return (
    <FieldSelect
      value={selectedValue}
      onChange={(_value, option) => onSelect(option)}
      isLoading={isLoading}
      options={institutions ?? []}
      focusColor="indigo"
      searchable
      {...rest}
    />
  );
}

InstitutionFilter.propTypes = {
  onChange: PropTypes.func,
  location: PropTypes.any,
};
InstitutionFilter.defaultProps = {
  onChange: _.noop,
  location: null,
};
