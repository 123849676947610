import { useMemo } from 'react';
import { useForm, isEmail, hasLength } from '@mantine/form';
import { Button, Group, TextInput, Switch } from '@mantine/core';
import { RolesSelect, InstitutionSelect } from 'components/fields';
import { IconLock, IconArrowLeft } from '@tabler/icons-react';
import { roles } from 'auth';
import PermissionGuard from 'components/PermissionGuard';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function UserForm({
  user,
  onSubmit,
  isSubmitting,
  onChangePasswordClick,
  onBackClick,
  visibilityClasses,
}) {
  const { t } = useTranslation();

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      structure: user?.structure ? `${user?.structure}` : '',
      claims: user?.claims,
      active: user?.active,
      roles: _.map(user?.roles, 'code'),
    },
    validate: {
      firstName: hasLength({ min: 3, max: 30 }, 'First Name must be 3-30 characters long'),
      lastName: hasLength({ min: 3, max: 30 }, 'Last Name must be 3-30 characters long'),
      email: isEmail('Invalid email'),
    },
    transformValues: (values) => ({
      ...values,
      roles: _.map(values.roles, (role) => ({ code: role })),
      structure: values.structure ? parseInt(values.structure, 10) : null,
    }),
  });
  const isCreateMode = useMemo(() => !user, [user]);

  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.last-name')}
          placeholder={t('labels.last-name')}
          withAsterisk
          {...form.getInputProps('lastName')}
        />
        <TextInput
          className="mt-4"
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.first-name')}
          placeholder={t('labels.first-name')}
          withAsterisk
          {...form.getInputProps('firstName')}
        />

        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.email')}
          placeholder={t('labels.email')}
          withAsterisk
          mt="md"
          {...form.getInputProps('email')}
        />
        <RolesSelect
          className="mt-4"
          label={t('labels.roles')}
          {...form.getInputProps('roles')}
          placeholder={t('placeholders.roles')}
        />
        <InstitutionSelect
          className="mt-4"
          label={t('labels.institution')}
          {...form.getInputProps('structure')}
          placeholder={t('placeholders.institution-select')}
        />
        <Switch
          labelPosition="left"
          className="mt-4"
          label={t('status.active')}
          checked={form.getValues().active}
          onChange={(e) => form.setFieldValue('active', e.currentTarget.checked)}
        />
        {!isCreateMode && (
          <PermissionGuard role={roles.admin}>
            <div className="mt-2">
              <Button
                p={0}
                color="red.5"
                variant="white"
                rightSection={<IconLock size={16} />}
                onClick={onChangePasswordClick}
              >
                {t('btn.set-pass')}
              </Button>
            </div>
          </PermissionGuard>
        )}

        <Group justify={isCreateMode || !onBackClick ? 'flex-end' : 'space-between'} className="mt-7">
          {!isCreateMode && onBackClick && (
            <Button
              variant="outline"
              leftSection={<IconArrowLeft size={16} />}
              radius="xl"
              color="gray.5"
              onClick={onBackClick}
            >
              {t('btn.back')}
            </Button>
          )}
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
UserForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
  onChangePasswordClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
};
UserForm.defaultProps = {
  user: null,
  onSubmit: _.noop,
  onChangePasswordClick: _.noop,
  onBackClick: null,
  isSubmitting: false,
  visibilityClasses: '',
};
