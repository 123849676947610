export const dashboardKeys = {
  totalParticipants: 'Q1',
  totalUnsatisfied: 'Q2',
  infoIndices: 'Q3',
  interactionSatisfaction: 'Q4',
  institutionTrustIndices: 'Q5',
  facilitiesIndices: 'Q6',
  institutionSections: 'Q7',
  patientSatisfactionStats: 'Q8',
  unofficialPaymentsStats: 'Q9',
  nonInsurancePayments: 'Q10',
  unofficialPaymentsCategories: 'Q11',
  facilitiesStats: 'Q12',
};

export const usefulDoc = {
  title: 'DocumenteUtile',
  ext: '.pdf',
  name: 'DocumenteUtile.pdf',
};
