import PropTypes from 'prop-types';
import { IconReportSearch } from '@tabler/icons-react';

export default function EmptyState({ message, className }) {
  return (
    <div className={`${className} flex flex-col justify-center items-center p-24 text-gray-300`}>
      <IconReportSearch className="size-20" />
      <span className="text-xl font-semibold">{message}</span>
    </div>
  );
}

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};
EmptyState.defaultProps = {
  className: '',
};
