import { TextInput } from '@mantine/core';
import classes from 'classnames';
import PropTypes from 'prop-types';

export default function TextField({ classNames, ...rest }) {
  const { input, ...other } = classNames;
  return (
    <TextInput
      classNames={{
        input: classes('focus:ring-1 focus:border-emerald-500 focus:ring-emerald-500', {
          [input]: Boolean(input),
        }),
        ...other,
      }}
      {...rest}
    />
  );
}
TextField.propTypes = {
  classNames: PropTypes.object,
};
TextField.defaultProps = {
  classNames: {},
};
