import { ImageDoctors } from 'components/icons';
import { Button } from 'components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useGetPublicTranslation } from 'api/hooks';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  backgroundImage: `url(/images/bg.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};
export default function CTAContainer({ onQuestionnaireClick, className, withImage }) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'CTATitle' });
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center" style={containerStyle}>
      <div
        className={classNames(
          className,
          'flex flex-col md:flex-row md:items-center md:justify-center space-y-8 md:space-y-0',
          {
            'py-24': !withImage,
            'pt-16': withImage,
          },
        )}
      >
        <span className="md:px-8 text-3xl font-extrabold flex-1">{title?.text}</span>

        <div className="md:px-8 flex md:justify-end flex-1">
          <Button radius="xl" color="gradient" onClick={onQuestionnaireClick}>
            {t('btn.questionnaire-complete')}
          </Button>
        </div>
      </div>
      {withImage && <ImageDoctors className="w-full md:w-[673px]" />}
    </div>
  );
}
CTAContainer.propTypes = {
  onQuestionnaireClick: PropTypes.func,
  className: PropTypes.string,
  withImage: PropTypes.bool,
};

CTAContainer.defaultProps = {
  onQuestionnaireClick: _.noop,
  className: '',
  withImage: false,
};
