/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { IconUsersSolid, EmojiFrowningFace } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { dashboardKeys } from 'api/constants';
import { StatsCard } from './components';

function UsersIcon({ ...rest }) {
  return (
    <div className="rounded-full size-9 flex justify-center items-center bg-indigo-600 text-white p-2.5">
      <IconUsersSolid {...rest} />
    </div>
  );
}
export default function GeneralStatsCards({ filter }) {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 col-span-2 xs:grid-cols-2 lg:grid-cols-3 gap-2">
      <StatsCard
        title={t('stats.total-participants')}
        icon={<UsersIcon />}
        queryKey={dashboardKeys.totalParticipants}
        filter={filter}
      />
      <StatsCard
        title={t('stats.total-unsatisfied')}
        icon={<EmojiFrowningFace className="size-9" />}
        queryKey={dashboardKeys.totalUnsatisfied}
        filter={filter}
      />
      <StatsCard title={t('stats.info-indices')} queryKey={dashboardKeys.infoIndices} filter={filter} />
      <StatsCard
        title={t('stats.interaction-satisfaction')}
        queryKey={dashboardKeys.interactionSatisfaction}
        filter={filter}
      />
      <StatsCard title={t('stats.trust-indices')} queryKey={dashboardKeys.institutionTrustIndices} filter={filter} />
      <StatsCard title={t('stats.facilities-indices')} queryKey={dashboardKeys.facilitiesIndices} filter={filter} />
    </div>
  );
}

GeneralStatsCards.propTypes = {
  filter: PropTypes.object,
};
GeneralStatsCards.defaultProps = {
  filter: {},
};
