import { Button } from 'components';
import { IconDownload, IconCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils';
import { useDownloadDocument } from 'api/hooks';

export default function QuestionnaireSubmitted() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument({
    onSuccess: (response) => {
      downloadFile(response, '');
    },
  });
  return (
    <div className="flex w-full justify-center items-center">
      <div className="animate-fade m-4 sm:w-container-sm md:w-container-md xl:w-container-xl w-full rounded-xl shadow-xl bg-white flex flex-col space-y-2">
        <div className="p-12">
          <div className="flex flex-col flex-grow space-y-4">
            <div className="flex justify-center">
              <div className="bg-emerald-500 rounded-full p-2">
                <IconCheck size="30px" color="white" />
              </div>
            </div>
            <span className="w-full text-center text-zinc-900 font-extrabold text-3xl md:text-5xl">
              {t('questionnaire.submitted-title')}
            </span>
            <span className="w-full text-center text-base md:text-lg text-zinc-600">
              {t('questionnaire.submitted-msg')}
            </span>

            <div className="flex flex-col sm:flex-row sm:space-x-2 justify-center">
              <Button color="primary" radius="xl" onClick={() => navigate('/', { replace: true })}>
                {t('btn.main-page')}
              </Button>
              <Button color="subtle" onClick={downloadDocument} rightSection={<IconDownload size={20} />} radius="xl">
                {t('btn.documents')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
