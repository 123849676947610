import { FieldSelect } from 'components/form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAgeGroups } from 'api/hooks';
import { useCallback, useState } from 'react';

export default function AgeGroupFilter({ onChange, ...rest }) {
  const { ageGroups, isLoading } = useAgeGroups({});
  const [selectedValue, setSelectedValue] = useState();

  const onSelect = useCallback(
    (option) => {
      setSelectedValue(option);
      onChange(option);
    },
    [onChange],
  );
  return (
    <FieldSelect
      value={selectedValue}
      onChange={(_value, option) => onSelect(option)}
      isLoading={isLoading}
      options={ageGroups ?? []}
      focusColor="indigo"
      {...rest}
    />
  );
}

AgeGroupFilter.propTypes = {
  onChange: PropTypes.func,
};
AgeGroupFilter.defaultProps = {
  onChange: _.noop,
};
