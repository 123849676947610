/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import _ from 'lodash';
import Checkbox from './Checkbox';

export default function CheckboxGroup({ options, error, values, onChange }) {
  const onCheckboxChanged = (e) => {
    const { item, checked } = e;
    const updatedValues = _.reject(values ?? [], ['code', item.code]);
    if (checked) {
      updatedValues.push(item);
    }
    onChange(updatedValues);
  };
  return (
    <div className="flex flex-col space-y-4 w-full">
      {_.map(options, (item) => {
        const isChecked = _.some(values ?? [], (v) => {
          return v.code === item.code;
        });
        return (
          <Checkbox key={item.code} option={item} checked={isChecked} onChange={onCheckboxChanged} label={item.name} />
        );
      })}
      {!_.isEmpty(error) && <p className="text-red-600 text-xs">{error} </p>}
    </div>
  );
}
CheckboxGroup.propTypes = {
  error: PropTypes.string,
  options: PropTypes.array.isRequired,
};
CheckboxGroup.defaultProps = {
  error: '',
};
