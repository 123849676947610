/* eslint-disable no-unused-vars */
import { Paper, Button, Anchor, Group, Table } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconEdit } from '@tabler/icons-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default function ContentDetails({ translation, onEditClick, visibilityClasses }) {
  const { t } = useTranslation();

  const translationValues = useMemo(() => {
    const merged = [_.pick(translation, ['language', 'text']), ...(translation?.mui ?? [])];
    return _.uniqBy(merged, 'language');
  }, [translation]);

  return (
    <div className={`flex flex-col space-y-4 ${visibilityClasses}`}>
      <Paper radius="8px" withBorder className="overflow-hidden">
        <ContentTranslationDetails translationKey={translation?.key ?? '-'} translations={translationValues} />
      </Paper>

      <div className="w-full flex justify-end">
        <Button
          className="w-min mt-4"
          onClick={onEditClick}
          color="indigo.6"
          radius="xl"
          leftSection={<IconEdit className="p-0.5" />}
        >
          {t('btn.edit')}
        </Button>
      </div>
    </div>
  );
}

ContentDetails.propTypes = {
  translation: PropTypes.object,
  onEditClick: PropTypes.func,
  visibilityClasses: PropTypes.string,
};
ContentDetails.defaultProps = {
  translation: null,
  onEditClick: _.noop,
  visibilityClasses: '',
};

function ContentTranslationDetails({ translationKey, translations }) {
  const { t } = useTranslation();
  return (
    <Table withColumnBorders classNames={{ th: 'font-medium py-3' }}>
      <Table.Thead className="bg-gray-50 text-gray-500 py-6">
        <Table.Tr>
          <Table.Th>{t('tables.key')}</Table.Th>

          {_.map(translations, (item) => (
            <Table.Th key={`th_${item.language}`}>{_.toUpper(item.language)}</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr className="text-gray-900 cursor-pointer">
          <Table.Td className="text-sm font-base py-4">{translationKey}</Table.Td>
          {_.map(translations, (item) => (
            <Table.Td key={item.language + item.text} className="text-sm font-base py-4">
              <span>{item.text ?? '-'}</span>
            </Table.Td>
          ))}
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
}
ContentTranslationDetails.propTypes = {
  translations: PropTypes.array.isRequired,
  translationKey: PropTypes.string.isRequired,
};
