import { useForm, isEmail, hasLength } from '@mantine/form';
import { Button, Group, TextInput } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function ProfileForm({ user, onSubmit, isSubmitting, onChangePasswordClick, visibilityClasses }) {
  const { t } = useTranslation();

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
    },
    validate: {
      firstName: hasLength({ min: 3, max: 30 }, 'First Name must be 3-30 characters long'),
      lastName: hasLength({ min: 3, max: 30 }, 'Last Name must be 3-30 characters long'),
      email: isEmail('Invalid email'),
    },
  });

  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.last-name')}
          placeholder={t('labels.last-name')}
          withAsterisk
          {...form.getInputProps('lastName')}
        />
        <TextInput
          className="mt-4"
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.first-name')}
          placeholder={t('labels.first-name')}
          withAsterisk
          {...form.getInputProps('firstName')}
        />

        <TextInput
          classNames={{
            input:
              'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
          }}
          label={t('labels.email')}
          placeholder={t('labels.email')}
          withAsterisk
          mt="md"
          {...form.getInputProps('email')}
        />
        <div className="mt-2">
          <Button
            p={0}
            color="red.5"
            variant="white"
            rightSection={<IconLock size={16} />}
            onClick={onChangePasswordClick}
          >
            {t('btn.set-pass')}
          </Button>
        </div>

        <Group justify="flex-end" className="mt-7">
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
ProfileForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
  onChangePasswordClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
};
ProfileForm.defaultProps = {
  user: null,
  onSubmit: _.noop,
  onChangePasswordClick: _.noop,
  onBackClick: null,
  isSubmitting: false,
  visibilityClasses: '',
};
