import { useRef } from 'react';
import { useForm } from '@mantine/form';
import { Button, Group, Text, Paper, rem } from '@mantine/core';
import { IconArrowLeft, IconX, IconCloudUpload, IconDownload } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone';
import { usefulDoc } from 'api/constants';

export default function FileUploadForm({ visibilityClasses, isSubmitting, onBackClick, onSubmit }) {
  const { t } = useTranslation();
  const openRef = useRef(null);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      file: null,
    },
    transformValues: (values) => {
      const renamedFile = new File([values.file], usefulDoc.name || values.file.name, {
        type: values.file.type,
      });

      return renamedFile;
    },
  });
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <div className="flex flex-col space-y-2 mb-4">
        <Paper withBorder p="md" shadow="md" className={visibilityClasses}>
          <div className="flex flex-col space-y-4">
            <Dropzone
              openRef={openRef}
              onDrop={(files) => {
                if (files && !_.isEmpty(files)) {
                  form.setFieldValue('file', files[0]);
                }
              }}
              className="border-1 pb-4 border-slate-300"
              radius="md"
              accept={[PDF_MIME_TYPE]}
              maxSize={30 * 1024 ** 2}
              maxFiles={1}
              multiple={false}
            >
              <div style={{ pointerEvents: 'none' }}>
                <Group justify="center">
                  <Dropzone.Accept>
                    <IconDownload style={{ width: rem(50), height: rem(50) }} color="indigo.6" stroke={1.5} />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX style={{ width: rem(50), height: rem(50) }} color="red.5" stroke={1.5} />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconCloudUpload style={{ width: rem(50), height: rem(50) }} stroke={1.5} />
                  </Dropzone.Idle>
                </Group>

                <Text ta="center" fw={700} fz="lg" mt="md">
                  <Dropzone.Accept>Adăugați fișierul aici</Dropzone.Accept>
                  <Dropzone.Reject>Fișierul .pdf nu trebuie să fie mai mare de 30mb</Dropzone.Reject>
                  <Dropzone.Idle>Adăugați fișier</Dropzone.Idle>
                </Text>
                <Text ta="center" fz="sm" mt="xs" c="dimmed">
                  {form.getValues().file && form.getValues().file.name}
                  {!form.getValues().file && (
                    <span>
                      Mutați fișierul aici. Fișierul trebuie să fie în format <i>.pdf</i> și nu mai mare de 30mb{' '}
                    </span>
                  )}
                </Text>
              </div>
            </Dropzone>

            <div className="flex justify-center py-2 items-center">
              <Button variant="outline" color="gray.5" radius="xl" onClick={() => openRef.current?.()}>
                {t('btn.select-file')}
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      <Group justify="space-between" className="mt-7">
        <Button
          variant="outline"
          leftSection={<IconArrowLeft size={16} />}
          radius="xl"
          color="gray.5"
          onClick={onBackClick}
        >
          {t('btn.back')}
        </Button>
        <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
          {t('btn.apply')}
        </Button>
      </Group>
    </form>
  );
}

FileUploadForm.propTypes = {
  visibilityClasses: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

FileUploadForm.defaultProps = {
  visibilityClasses: '',
  isSubmitting: false,
  onBackClick: _.noop,
  onSubmit: _.noop,
};
