/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Modal, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useGetTranslation } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import ContentDetails from './ContentDetails';
import ContentForm from './ContentForm';

export default function ContentModal({ opened, onClose, translation, onSubmit, isSubmitting }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();
  const { translation: translationDetails, isLoading } = useGetTranslation(translation ?? {}, {
    enabled: Boolean(translation?.resourceKey),
  });

  const [visibleComponent, setVisibleComponent] = useState('details');

  const modalTitle = useMemo(() => {
    const formVisible = visibleComponent === 'form';
    if (formVisible) {
      return t('titles.edit');
    }
    return t('titles.details');
  }, [visibleComponent, t]);

  useEffect(() => {
    setVisibleComponent(translation ? 'details' : 'form');
  }, [translation]);

  return (
    <Modal
      size="80%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent('details');
      }}
      centered
      keepMounted={false}
      title={modalTitle}
    >
      <div className="p-4">
        {isLoading && (
          <div className="flex justify-center items-center p-40">
            <Loader color="indigo.6" />
          </div>
        )}
        {!isLoading && (
          <VisibilityWrapper value={visibleComponent}>
            <ContentDetails
              componentKey="details"
              translation={translationDetails}
              onEditClick={() => setVisibleComponent('form')}
            />

            <ContentForm
              componentKey="form"
              onBackClick={() => setVisibleComponent('details')}
              translation={translationDetails}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          </VisibilityWrapper>
        )}
      </div>
    </Modal>
  );
}
ContentModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  translation: PropTypes.object,
};
ContentModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  translation: null,
};
