import _ from 'lodash';

const getFileExtension = (response) => {
  try {
    const requestUrl = new URL(response?.request?.responseURL);
    const { pathname } = requestUrl;
    return _.last(pathname.split('/')) ?? '.txt';
  } catch (_e) {
    return '.txt';
  }
};

export const downloadFile = async (response, fileName = 'document') => {
  const extension = getFileExtension(response);

  const binaryString = atob(response.data);
  const bytes = new Uint8Array(binaryString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes]);
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
