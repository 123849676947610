import { Routes, Route, Navigate } from 'react-router-dom';
import { QuestionnaireIntroduction, Questionnaire, MainContainer, Landing } from 'modules';
import { QuestionnaireProvider } from 'modules/questionnaire';

function QuestionnaireRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<QuestionnaireIntroduction />} />
      <Route exact path="/:locale/:institutionId/" element={<Questionnaire />} />
    </Routes>
  );
}
function App() {
  return (
    <Routes>
      <Route
        exact
        path="/questionnaire/*"
        element={
          <QuestionnaireProvider>
            <QuestionnaireRoutes />
          </QuestionnaireProvider>
        }
      />
      <Route exact path="/*" element={<MainContainer />} />
      <Route exact path="/" element={<Landing />} />

      <Route path="*" element={<Navigate to="/questionnaire" replace />} />
    </Routes>
  );
}

export default App;
