/* eslint-disable react/prop-types */
import { Paper, Collapse, UnstyledButton, Table, Spoiler, Radio, TextInput, Checkbox, Rating } from '@mantine/core';
import PropTypes from 'prop-types';

import { IconChevronDown, IconCheck } from '@tabler/icons-react';
import _ from 'lodash';
import { useDisclosure } from '@mantine/hooks';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useDictionary } from 'api/hooks';

export default function QuestionnaireDetailsSection({ section, index }) {
  const [opened, { toggle }] = useDisclosure(false);
  const showIndex = index > 0;
  return (
    <div className="flex flex-col space-y-4">
      <UnstyledButton className="flex w-full items-center" onClick={toggle}>
        <div className="flex flex-col flex-grow space-y-2 pr-4">
          <span className="text-xl text-gray-900 font-semibold">
            {showIndex ? `${index}.` : ''} {section.name}
          </span>
          <span className="text-md text-gray-400 max-w-3/4">{section.description}</span>
        </div>
        <div>
          <IconChevronDown
            className={classNames('size-4 transform transition-transform duration-300', { 'rotate-180': opened })}
          />
        </div>
      </UnstyledButton>
      <Collapse in={opened}>
        <QuestionnaireDetailsBody fields={section.fields} sectionKey={section.code} />
      </Collapse>
    </div>
  );
}

function AnswerExampleWrapper({ title, children }) {
  return (
    <div className="flex flex-col space-y-1">
      <span className="font-semibold text-md mt-2">{title}</span>
      {children}
    </div>
  );
}
function AnswerExample({ field, sectionKey }) {
  const fieldType = field?.uiHint ?? field?.ui_hint;

  if (fieldType === 'RadioGroup') {
    return (
      <AnswerExampleWrapper title="Selecție unică (RadioButton)">
        <RadioGroupExample field={field} sectionKey={sectionKey} />
      </AnswerExampleWrapper>
    );
  }
  if (fieldType === 'DropDownBox') {
    return (
      <AnswerExampleWrapper title="Listă de răspunsuri (Dropdown)">
        <AnswerVariantsExample field={field} />
      </AnswerExampleWrapper>
    );
  }
  if (fieldType === 'TextBox' || _.isNil(fieldType)) {
    return (
      <AnswerExampleWrapper title="Câmp de text">
        <TextFieldExample field={field} />
      </AnswerExampleWrapper>
    );
  }
  if (fieldType === 'CheckBox') {
    return (
      <AnswerExampleWrapper title="Răspunsuri multiple (Checkbox)">
        <CheckboxExample field={field} sectionKey={sectionKey} />
      </AnswerExampleWrapper>
    );
  }
  if (fieldType === 'Rating') {
    return (
      <AnswerExampleWrapper title="Rating">
        <RatingExample field={field} />
      </AnswerExampleWrapper>
    );
  }
  return <span>{field.uiHint ?? field.ui_hint}</span>;
}
function QuestionnaireDetailsBody({ fields, sectionKey }) {
  const rows = _.map(fields, (field) => {
    return (
      <Table.Tr key={field.code}>
        <Table.Td>
          <div className="flex flex-col space-y-2">
            <div className="font-semibold">
              {field.name} {field.is_required && <span className="text-red-500 text-lg">*</span>}
            </div>
            <div className="text-gray-600">
              <Spoiler
                classNames={{ control: 'text-sm mt-1 text-gray-400' }}
                maxHeight={60}
                showLabel="Vezi mai mult"
                hideLabel="Ascunde"
              >
                {field.description}
              </Spoiler>
            </div>
          </div>
        </Table.Td>
        <Table.Td className="md:w-[300px]">
          <div className="text-sm ">
            <AnswerExample field={field} sectionKey={sectionKey} />
          </div>
        </Table.Td>
      </Table.Tr>
    );
  });
  return (
    <Paper withBorder shadow="xs">
      <Table withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Întrebare</Table.Th>
            <Table.Th>Variante de răspuns</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
}
QuestionnaireDetailsBody.propTypes = {
  fields: PropTypes.array.isRequired,
};
QuestionnaireDetailsSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number,
};
QuestionnaireDetailsSection.defaultProps = {
  index: -1,
};

function TextFieldExample() {
  return (
    <div className="p-4 w-full">
      <TextInput value="" disabled placeholder="Introduceți răspunsul" />
    </div>
  );
}
function RatingExample() {
  return (
    <div className="p-4 w-max">
      <Rating readOnly />
    </div>
  );
}
function AnswerVariantsExample({ field }) {
  const { dictionary } = useDictionary(field.dictionary);
  const fieldOptions = useMemo(() => {
    if (field?.uiHintSource && !_.isEmpty(field?.uiHintSource)) {
      return field.uiHintSource;
    }
    return _.map(dictionary, (option) => ({ code: option.code, name: option.name }));
  }, [field, dictionary]);

  return (
    <div className="py-4 text-sm flex flex-col space-y-4">
      {_.map(fieldOptions, (item) => (
        <div className="flex space-x-2 items-center rounded-md bg-gray-100 p-2" key={item.code}>
          <IconCheck className="size-5 flex-none text-gray-400" />
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
}
function CheckboxExample({ field, sectionKey }) {
  const { dictionary } = useDictionary(field.dictionary);
  const fieldOptions = useMemo(() => {
    if (field?.uiHintSource && !_.isEmpty(field?.uiHintSource)) {
      return field.uiHintSource;
    }
    return _.map(dictionary, (option) => ({ code: option.code, name: option.name }));
  }, [field, dictionary]);

  return (
    <div className="flex flex-col space-y-2 py-4">
      {_.map(fieldOptions, (item) => {
        return (
          <div className="w-full text-wrap" key={item.code + sectionKey}>
            <Checkbox
              checked={false}
              label={item.name}
              readOnly
              classNames={{ label: 'text-sm' }}
              className="px-4 py-3 rounded-md max-w-max cursor-pointer bg-slate-100 text-slate-900 text-sm"
            />
          </div>
        );
      })}
    </div>
  );
}
function RadioGroupExample({ field, sectionKey }) {
  const { dictionary } = useDictionary(field.dictionary);

  const fieldOptions = useMemo(() => {
    if (field?.uiHintSource && !_.isEmpty(field?.uiHintSource)) {
      return field.uiHintSource;
    }
    return _.map(dictionary, (option) => ({ code: option.code, name: option.name }));
  }, [field, dictionary]);
  return (
    <div className="flex flex-col space-y-2 py-4 ">
      {_.map(fieldOptions, (item) => {
        return (
          <Radio
            key={item.code + sectionKey}
            readOnly
            checked={false}
            label={item.name}
            classNames={{ label: 'text-sm' }}
            className="px-4 py-3 rounded-md flex-none bg-slate-100 text-slate-900 w-max "
          />
        );
      })}
    </div>
  );
}
