import { useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LocaleContext from './LocaleContext';
import locales from './locales';
import i18n from './i18n';

export default function LocaleProvider({ children }) {
  const getLocaleByCode = (key) => _.find(Object.values(locales), (l) => l.key === key);

  const storedLocale = localStorage.getItem('localeCode');
  const [currentLocale, setLocale] = useState(getLocaleByCode(storedLocale) ?? locales.ro);

  useEffect(() => {
    i18n.changeLanguage(currentLocale.key);
  }, [currentLocale]);

  const updateLocale = useCallback((key) => {
    const locale = getLocaleByCode(key);
    if (_.isNil(locale)) return;
    localStorage.setItem('localeCode', key);
    setLocale(locale);
  }, []);

  const value = useMemo(() => {
    return { updateLocale, currentLocale, locales };
  }, [currentLocale, updateLocale]);
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
