/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      boxShadow: {
        vp: '0px 4px 25px 0px rgba(0, 0, 0, 0.1)',
      },
      colors: {
        vp: {
          primary: '#2CBE9D',
        },
      },
    },
  },
  plugins: [],
};
