import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Layout({ className, children }) {
  const containerStyle = {
    backgroundImage: `url(/images/bg.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <div className="w-full h-full">
      <div className="absolute top-0 left-0 right-0 z-0">
        <div className="fixed h-4/5 w-full" style={containerStyle} />
      </div>
      <div className={classNames('absolute w-full h-full', className)}>{children}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Layout.defaultProps = {
  className: '',
};
