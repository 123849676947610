import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton, Group } from '@mantine/core';
import { DonutChart } from '@mantine/charts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { dashboardKeys } from 'api/constants';
import { ChartTitle, StatsLabelValue } from './components';

export default function UnofficialPaymentsStats({ filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey: dashboardKeys.unofficialPaymentsStats,
    ...filter,
  });
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const yes = _.find(stats, (item) => item?.mainCode === '1');
      const no = _.find(stats, (item) => item?.mainCode === '0');
      const data = [
        { name: t('stats.yes'), value: yes?.total ?? 0, color: 'indigo.5', legendColor: 'bg-indigo-500' },
        {
          name: t('stats.no'),
          value: no?.value ?? 0,
          color: 'yellow.4',
          legendColor: 'bg-yellow-400',
        },
      ];
      return data;
    }
    return [];
  }, [stats, t]);
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={t('stats.titles.unofficial-payments')} />
          <div className="flex flex-col justify-center items-center">
            <DonutChart data={chartData} size={200} thickness={40} />
            {withLegend && (
              <div className="flex flex-col space-y-2 mt-8">
                <StatsLabelValue label={t('stats.total')} value={stats?.satisfactionStats?.total} />
                {_.map(chartData, (item) => {
                  return (
                    <div className="flex space-x-2 items-center" key={item.name}>
                      <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                      <StatsLabelValue label={item.name} value={item.value} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Skeleton>
  );
}

function UnofficialPaymentsByCategories({ title, queryKey, filter }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey,
    ...filter,
  });
  const { t } = useTranslation();
  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const colors = ['indigo.5', 'yellow.4', 'red.4', 'emerald.5', 'gray.4'];
      const legendColors = ['bg-indigo-500', 'bg-yellow-400', 'bg-red-400', 'bg-emerald-500', 'bg-gray-400'];
      const data = _.map(stats, (c, index) => ({
        name: c.mainName,
        value: c.total ?? 0,
        color: colors[index],
        legendColor: legendColors[index],
      }));
      const hasData = _.sumBy(data, 'value') > 0;
      return hasData ? data : [{ key: 'noData', name: t('messages.no-data'), value: 1, color: 'zinc.3' }];
    }
    return [];
  }, [stats, t]);
  const hasData = _.sumBy(chartData, 'value') > 0;
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={title} />
          <Group>
            <DonutChart data={chartData} withTooltip={hasData} thickness={30} />
            <div className="w-max max-w-1/2 space-y-2">
              {_.map(chartData, (item) => {
                return (
                  <div className="flex space-x-2 items-center" key={item.name}>
                    <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                    <StatsLabelValue label={item.name} value={item.value} />
                  </div>
                );
              })}
            </div>
          </Group>
        </div>
      </Paper>
    </Skeleton>
  );
}
UnofficialPaymentsByCategories.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
UnofficialPaymentsByCategories.defaultProps = {
  filter: {},
};

UnofficialPaymentsStats.ByCategories = UnofficialPaymentsByCategories;

UnofficialPaymentsStats.propTypes = {
  filter: PropTypes.object,
  withLegend: PropTypes.bool,
};
UnofficialPaymentsStats.defaultProps = {
  filter: {},
  withLegend: false,
};
