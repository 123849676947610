import { Checkbox as MtnCheckobx } from '@mantine/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

export default function Checkbox({ option, onChange, checked, ...rest }) {
  return (
    <div className="w-full text-wrap">
      <MtnCheckobx
        color="emerald.5"
        checked={checked}
        className={classNames(`px-4 py-3 rounded-md max-w-max cursor-pointer`, {
          'bg-emerald-50 text-emerald-900': checked,
          'bg-slate-100 text-slate-900': !checked,
        })}
        onChange={(e) => {
          onChange({ item: option, checked: e.currentTarget.checked });
        }}
        {...rest}
      />
    </div>
  );
}
Checkbox.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};
Checkbox.defaultProps = {
  onChange: _.noop,
  checked: false,
};
