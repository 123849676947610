/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InstitutionsTable, InstitutionModal } from 'components';
import { LocationFilter, InstitutionCategoryFilter } from 'components/filters';
import { useGetNodes, useCreateNode, useUpdateNode } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Pagination, Popover, Input, Button } from '@mantine/core';
import { IconCalendar, IconSearch, IconPlus } from '@tabler/icons-react';
import { useStateDisclosure } from 'utils';
import { DateRangePicker } from 'components/date-range-picker';
import { useDebouncedValue } from '@mantine/hooks';

export default function Institutions() {
  const { page } = useParams();
  const [modalOpened, modalHandler] = useStateDisclosure(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!page || page < 1) {
      navigate('/institutions/1', { replace: true });
    }
  }, [page, navigate]);
  const { t } = useTranslation();

  const [institutionCategoryFilter, setInstitutionCategoryFilter] = useState();
  const [locationFilter, setLocationFilter] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 800);

  const [filter, setFilter] = useState({});
  useEffect(() => {
    const updatedFilter = {};

    if (debouncedSearchTerm && !_.isEmpty(debouncedSearchTerm)) {
      updatedFilter.FilterText = debouncedSearchTerm;
    }
    if (institutionCategoryFilter) {
      updatedFilter.Category = institutionCategoryFilter.item.id;
    }

    if (locationFilter) {
      updatedFilter.Locality = locationFilter.item.id;
    }
    setFilter(updatedFilter);
  }, [locationFilter, institutionCategoryFilter, debouncedSearchTerm]);

  const { nodes, isLoading, refetch } = useGetNodes({ page, params: filter });
  const totalPages = useMemo(() => Math.ceil(_.divide(nodes?.count, nodes?.pageSize)), [nodes]);

  const onNodeClick = useCallback(
    (node) => {
      modalHandler.open(node);
    },
    [modalHandler],
  );

  const { createNode, isLoading: isCreatingNode } = useCreateNode({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const { updateNode, isLoading: isUpdatingNode } = useUpdateNode({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });

  const createNodeSubmit = useCallback(
    (values) => {
      createNode(_.omit(values, ['code']));
    },
    [createNode],
  );

  const updateNodeSubmit = useCallback(
    (values) => {
      if (modalHandler.state) {
        updateNode({ id: modalHandler.state?.id, payload: values });
      }
    },
    [modalHandler.state, updateNode],
  );

  return (
    <div className="w-full bg-gray-100 ">
      <InstitutionModal
        onSubmit={modalHandler.state ? updateNodeSubmit : createNodeSubmit}
        isSubmitting={isCreatingNode || isUpdatingNode}
        opened={modalOpened}
        onClose={modalHandler.close}
        institution={modalHandler.state}
      />
      <div className="px-4 bg-white shadow">
        <div className="flex flex-col w-full items-center sm:flex-row justify-start sm:justify-between py-4 space-y-4 sm:space-y-0 ">
          <span className="self-start text-lg font-medium mr-4">{t('pages.institutions')}</span>
          <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 sm:justify-end w-full">
            <div>
              <Input
                className="sm:flex-grow"
                onChange={(event) => setSearchTerm(event.currentTarget.value)}
                value={searchTerm}
                placeholder={t('labels.search')}
                classNames={{
                  input:
                    'text-gray-500 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                leftSection={<IconSearch className=" text-gray-500" size={16} />}
              />
            </div>
            <div>
              <LocationFilter placeholder={t('placeholders.location')} onChange={setLocationFilter} clearable />
            </div>
            <div>
              <InstitutionCategoryFilter
                placeholder={t('placeholders.institution-category')}
                onChange={setInstitutionCategoryFilter}
                clearable
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end pb-4">
          <Button
            className="w-min"
            variant="outline"
            color="gray.5"
            radius="xl"
            leftSection={<IconPlus className="p-0.5" />}
            onClick={() => modalHandler.open()}
          >
            {t('btn.institution-add')}
          </Button>
        </div>
      </div>
      <div className="p-6">
        <InstitutionsTable data={nodes?.data ?? []} isLoading={isLoading} onRowClick={onNodeClick} />
        <div className="flex justify-end py-4">
          <Pagination
            total={totalPages}
            color="gray.3"
            classNames={{
              control:
                'data-[active=true]:border-2 data-[active=true]:border-indigo-600 data-[active=true]:bg-indigo-100 data-[active=true]:text-indigo-600 text-gray-900',
            }}
            autoContrast
            onChange={(value) => {
              navigate(`/institutions/${value}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
