import { Button, QuestionnaireSection, EmojiSelect } from 'components';
import classNames from 'classnames';
import { IconArrowRight, IconArrowLeft, IconClock } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useQuestionnaireContext } from 'modules/questionnaire/hooks/useQuestionnaireContext';

export default function GeneralImpression({ onBackClick, onStartClick, style, isVisible, startEnabled }) {
  const animationClasses = isVisible ? 'animate-fade-left' : 'animate-fade-right animate-reverse hidden';
  const { t } = useTranslation();
  const { institutionConfig, generalImpression: generalImpressionConfig } = useQuestionnaireContext();
  const { generalImpression, impressions } = generalImpressionConfig;
  return (
    <div style={style} className={`${animationClasses} w-full rounded-xl shadow-xl bg-white flex flex-col space-y-2`}>
      <div className="p-8 space-y-4">
        <div className="flex flex-col flex-grow space-y-8 text-base text-zinc-900">
          {t('questionnaire.explanation', { institutionName: institutionConfig?.institution?.label })}
        </div>
        <div className="flex space-x-3">
          <div className="p-1 rounded-md bg-white shadow-md  border border-zinc-200 text-indigo-600 h-min">
            <IconClock size="20px" />
          </div>
          <div className="text-base text-zinc-900">{t('questionnaire.explanation-description')}</div>
        </div>
        <div className="text-base text-zinc-500">{t('questionnaire.explanation-thanks')}</div>
        <div className="flex flex-col md:flex-row justify-between md:space-x-2.5 md:space-y-0 space-y-2.5">
          <EmojiSelect
            label={t('labels.satisfied')}
            emoji="grinning"
            onClick={() => generalImpressionConfig.setGeneralImpression(impressions.satisfied)}
            isSelected={generalImpression?.code === impressions.satisfied.code}
          />
          <EmojiSelect
            label={t('labels.partial-satisfied')}
            emoji="neutral"
            onClick={() => generalImpressionConfig.setGeneralImpression(impressions.neutral)}
            isSelected={generalImpression?.code === impressions.neutral.code}
          />
          <EmojiSelect
            label={t('labels.dissatisfied')}
            emoji="frowning"
            onClick={() => generalImpressionConfig.setGeneralImpression(impressions.dissatisfied)}
            isSelected={generalImpression?.code === impressions.dissatisfied.code}
          />
        </div>
      </div>
      <QuestionnaireSection.Footer className={classNames('justify-between')}>
        <Button
          onClick={onBackClick}
          color="default"
          variant="default"
          leftSection={<IconArrowLeft size={20} />}
          radius="xl"
        >
          {t('btn.back')}
        </Button>

        <Button
          disabled={!startEnabled || !generalImpression?.code}
          color="primary"
          onClick={onStartClick}
          rightSection={<IconArrowRight size={20} />}
          radius="xl"
        >
          {t('start')}
        </Button>
      </QuestionnaireSection.Footer>
    </div>
  );
}

GeneralImpression.propTypes = {
  onBackClick: PropTypes.func,
  onStartClick: PropTypes.func,
  style: PropTypes.object,
  isVisible: PropTypes.bool,
  startEnabled: PropTypes.bool,
};
GeneralImpression.defaultProps = {
  onBackClick: _.noop,
  onStartClick: _.noop,
  style: {},
  isVisible: true,
  startEnabled: false,
};
