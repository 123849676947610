import PropTypes from 'prop-types';
import { useHasRole } from 'auth/hooks/useHasRole';
import _ from 'lodash';

export default function PermissionGuard({ role, children, fallback: FallbackComponent }) {
  const hasRole = useHasRole(_.isArray(role) ? role : [role]);
  return hasRole ? children : FallbackComponent;
}

PermissionGuard.propTypes = {
  role: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

PermissionGuard.defaultProps = {
  fallback: null,
};
