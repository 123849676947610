import _ from 'lodash';
import { useAuthContext } from './useAuthContext';

export const useHasRole = (roles) => {
  const { user } = useAuthContext();
  if (user && !_.isEmpty(user.roles)) {
    const userRoles = _.map(user.roles, (role) => role.code);
    const hasRole = _.some(roles, (role) => _.includes(userRoles, role));
    return hasRole;
  }
  return false;
};
