import PropTypes from 'prop-types';

export default function StatsLabelValue({ label, value }) {
  return (
    <div>
      <span className="text-sm text-gray-500">{label}</span>:{' '}
      <span className="text-gray-900 font-bold text-sm">{value}</span>
    </div>
  );
}
StatsLabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};
StatsLabelValue.defaultProps = {
  label: '',
  value: '',
};
