import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';

export default function LinksContainer({ className }) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'LinksTitle' });

  return (
    <div
      className={classNames(
        className,
        'flex flex-col space-y-8 md:space-y-0 md:flex-row bg-white items-start md:justify-between md:py-24 py-12',
      )}
    >
      <span className="md:px-8 text-3xl font-extrabold sm:w-1/3">{title?.text}</span>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:px-8">
        {_.map(_.range(4), (i) => (
          <LinkItem index={i} key={i} />
        ))}
      </div>
    </div>
  );
}

function LinkItem({ index }) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: `Link[${index}]Title` });
  const { translation: link } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `Link[${index}]Link`,
  });
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-lg font-base">{title?.text}</span>
      <a className="text-indigo-600 underline" href={link?.text}>
        {link?.text}
      </a>
    </div>
  );
}
LinkItem.propTypes = {
  index: PropTypes.number.isRequired,
};
LinksContainer.propTypes = {
  className: PropTypes.string,
};
LinksContainer.defaultProps = {
  className: '',
};
