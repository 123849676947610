import { useReport } from 'api/hooks';
import { LoadingOverlay, Divider, Paper } from '@mantine/core';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { IconBuilding, IconTag, IconMap2, IconMoodSmile, IconCalendarClock } from '@tabler/icons-react';
import _ from 'lodash';
import ReportSection from './ReportSection';

const getFormattedDate = (date) => {
  return date ? format(parseISO(date), 'dd/MM/yyyy hh:mm') : '';
};
export default function ReportDetails({ id }) {
  const { report, isLoading } = useReport(id, { enabled: Boolean(id) });
  return (
    <div className="w-full flex flex-col space-y-4 py-4 px-8">
      <LoadingOverlay visible={isLoading} zIndex={9000} size={30} overlayProps={{ radius: 'sm', blur: 2 }} />
      {!isLoading && (
        <>
          <div className="w-full text-center text-3xl font-semibold text-gray-900">{report?.title}</div>
          <div className="max-w-full text-wrap mx-8 text-center text-lg font-normal text-gray-500">
            {report?.description}
          </div>
          <Divider />
          <Paper p="xl">
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-2 text-gray-500 ">
                <IconBuilding className="size-5" />
                <span>{report?.institution}</span>
              </div>
              <div className="flex space-x-2 text-gray-500 ">
                <IconTag className="size-5" />
                <span>{report?.institutionCategory}</span>
              </div>
              <div className="flex space-x-2 text-gray-500">
                <IconMap2 className="size-5" />
                <span>{report?.locality}</span>
              </div>
              <div className="flex space-x-2 text-gray-500 ">
                <IconMoodSmile className="size-5" />
                <span>{report?.satisfaction}</span>
              </div>
              <div className="flex space-x-2 text-gray-500 ">
                <IconCalendarClock className="size-5" />
                <span>{getFormattedDate(report?.createdAt)}</span>
              </div>
            </div>
          </Paper>
          <Divider />
          {_.map(report?.sections, (item, index) => (
            <div key={item.code} className="flex flex-col space-y-4">
              <ReportSection key={item.code} index={index + 1} section={item} />
              <Divider />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

ReportDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ReportDetails.defaultProps = {
  id: null,
};
