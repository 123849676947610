import PropTypes from 'prop-types';
import { Paper, Skeleton } from '@mantine/core';
import { IconArrowNarrowUp, IconArrowNarrowDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { useDashboardTrends } from 'api/hooks';

export default function StatsCard({ filter, title, queryKey, icon: Icon }) {
  const { stats, isLoading } = useDashboardTrends({ queryKey, ...filter });
  const growth = stats?.trend ?? 0;
  const positiveGrowth = growth > 0;
  const GrowthIcon = positiveGrowth ? IconArrowNarrowUp : IconArrowNarrowDown;
  return (
    <Skeleton visible={isLoading}>
      <Paper shadow="xs" radius="6px" className="p-4 h-full">
        <div className="flex space-x-3">
          {Icon}
          <div className="h-full flex flex-col space-y">
            <span className="text-sm font-medium text-gray-500"> {title}</span>
            <div className="flex items-end space-x-2">
              <div className="flex">
                <span className="text-2xl text-semibold text-gray-900">{stats?.count ?? 0}</span>
                <span className="text-2xl text-semibold text-gray-400">/{stats?.relationship ?? 5}</span>
              </div>
              <div
                className={classNames('flex  justify-center items-center font-semibold text-sm', {
                  'text-emerald-500': positiveGrowth,
                  'text-red-500': !positiveGrowth,
                })}
              >
                <GrowthIcon size="20px" />
                <span>{growth}</span>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Skeleton>
  );
}
StatsCard.propTypes = {
  title: PropTypes.string,
  queryKey: PropTypes.string.isRequired,
  icon: PropTypes.node,
  filter: PropTypes.object,
};
StatsCard.defaultProps = {
  title: '',
  icon: null,
  filter: {},
};
