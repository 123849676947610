import { useCallback, useEffect, useMemo, useState } from 'react';
import { UsersTable, UserModal } from 'components';
import { InstitutionFilter } from 'components/filters';
import { useGetUsers, useCreateUser, useUpdateUser, useSetUserPassword } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Pagination, Popover, Input, Button } from '@mantine/core';
import { IconCalendar, IconSearch, IconPlus } from '@tabler/icons-react';
import { useStateDisclosure } from 'utils';
import { DateRangePicker } from 'components/date-range-picker';
import { useDebouncedValue } from '@mantine/hooks';

export default function Users() {
  const { page } = useParams();
  const [modalOpened, modalHandler] = useStateDisclosure(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!page || page < 1) {
      navigate('/users/1', { replace: true });
    }
  }, [page, navigate]);
  const { t } = useTranslation();

  const [institutionFilter, setInstitutionFilter] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 800);

  const [filter, setFilter] = useState({});
  const [periodFilter, setPeriodFilter] = useState({});
  useEffect(() => {
    const updatedFilter = {};

    if (debouncedSearchTerm && !_.isEmpty(debouncedSearchTerm)) {
      updatedFilter.FilterText = debouncedSearchTerm;
    }
    if (institutionFilter) {
      updatedFilter.Institution = institutionFilter.item.id;
    }

    if (periodFilter?.startDate) {
      updatedFilter.DateFrom = periodFilter.startDate;
    }
    if (periodFilter?.endDate) {
      updatedFilter.DateTo = periodFilter.endDate;
    }
    setFilter(updatedFilter);
  }, [periodFilter, institutionFilter, debouncedSearchTerm]);

  const { users, isLoading, refetch } = useGetUsers({ page, params: filter });
  const totalPages = useMemo(() => Math.ceil(_.divide(users?.count, users?.pageSize)), [users]);

  const [pickerOpened, setPickerOpened] = useState(false);

  const onPeriodChange = useCallback((range) => {
    setPeriodFilter(range);
    setPickerOpened(false);
  }, []);

  const customRangeTitle = useMemo(() => {
    if (_.isEmpty(periodFilter)) {
      return t('periods.generic');
    }
    const from = periodFilter?.startDate ?? '';
    const to = periodFilter?.endDate ?? t('labels.today');
    return _.join([from, to].filter(Boolean), ' | ');
  }, [t, periodFilter]);

  const onUserEditClick = useCallback(
    (user) => {
      modalHandler.open(user.id);
    },
    [modalHandler],
  );

  const { createUser, isLoading: isCreatingUser } = useCreateUser({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const { updateUser, isLoading: isUpdatingUser } = useUpdateUser({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const createUserSubmit = useCallback(
    (values) => {
      createUser(values);
    },
    [createUser],
  );
  const updateUserSubmit = useCallback(
    (values) => {
      if (modalHandler.state) {
        updateUser({ id: modalHandler.state, payload: values });
      }
    },
    [modalHandler.state, updateUser],
  );
  const { setUserPassword, isLoading: isSettingPassword } = useSetUserPassword({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const onPasswordSubmit = useCallback(
    (values) => {
      if (modalHandler.state) {
        const { userId, password } = values;
        setUserPassword({ id: userId, payload: { password } });
      }
    },
    [modalHandler.state, setUserPassword],
  );

  return (
    <div className="w-full bg-gray-100 ">
      <UserModal
        opened={modalOpened}
        onClose={modalHandler.close}
        userId={modalHandler.state}
        onSubmit={modalHandler.state ? updateUserSubmit : createUserSubmit}
        isSubmitting={isCreatingUser || isUpdatingUser || isSettingPassword}
        onPasswordChange={onPasswordSubmit}
      />
      <div className="px-4 bg-white shadow">
        <div className="flex flex-col w-full items-center sm:flex-row justify-start sm:justify-between py-4 space-y-4 sm:space-y-0 ">
          <span className="self-start text-lg font-medium mr-4">{t('pages.users')}</span>
          <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 sm:justify-end w-full">
            <div>
              <Input
                className="sm:flex-grow"
                onChange={(event) => setSearchTerm(event.currentTarget.value)}
                value={searchTerm}
                placeholder={t('labels.search')}
                classNames={{
                  input:
                    'text-gray-500 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                leftSection={<IconSearch className=" text-gray-500" size={16} />}
              />
            </div>
            <div>
              <InstitutionFilter
                placeholder={t('placeholders.institution')}
                onChange={setInstitutionFilter}
                clearable
              />
            </div>
            <Popover keepMounted shadow="xl" opened={pickerOpened} onClose={() => setPickerOpened(false)}>
              <Popover.Target>
                <div>
                  <Input
                    className="sm:flex-grow"
                    readOnly
                    onClick={() => setPickerOpened((prev) => !prev)}
                    value={customRangeTitle}
                    classNames={{
                      input:
                        'text-gray-500 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                    }}
                    leftSection={<IconCalendar className=" text-gray-500" size={16} />}
                  />
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <DateRangePicker onChange={onPeriodChange} />
              </Popover.Dropdown>
            </Popover>
          </div>
        </div>
        <div className="flex w-full justify-end pb-4">
          <Button
            className="w-min"
            variant="outline"
            color="gray.5"
            radius="xl"
            leftSection={<IconPlus className="p-0.5" />}
            onClick={() => modalHandler.open()}
          >
            {t('btn.user-add')}
          </Button>
        </div>
      </div>
      <div className="p-6">
        <UsersTable data={users?.data ?? []} isLoading={isLoading} onRowClick={onUserEditClick} />
        <div className="flex justify-end py-4">
          <Pagination
            total={totalPages}
            color="gray.3"
            classNames={{
              control:
                'data-[active=true]:border-2 data-[active=true]:border-indigo-600 data-[active=true]:bg-indigo-100 data-[active=true]:text-indigo-600 text-gray-900',
            }}
            autoContrast
            onChange={(value) => {
              navigate(`/users/${value}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
