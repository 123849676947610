import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Paper, Table, Badge, ActionIcon } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components/ui-messages';
import _ from 'lodash';

export default function InstitutionsTable({ data, onRowClick, isLoading }) {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return _.map(data, (item) => (
      <Table.Tr
        key={item.id}
        className="text-gray-900 cursor-pointer"
        onClick={() => {
          onRowClick(item);
        }}
      >
        <Table.Td align="center">{item.id}</Table.Td>
        <Table.Td className="text-sm font-base">
          <div className="flex space-x-1 items-center">
            <ActionIcon
              component="a"
              href={item.url}
              disabled={!item.url}
              target="_blank"
              size="sm"
              color="indigo.6"
              className="bg-transparent"
              variant="transparent"
              onClick={(event) => event.stopPropagation()}
            >
              <IconLink className="size-5" />
            </ActionIcon>

            <span>{item.name ?? '-'}</span>
          </div>
        </Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{item.categoryName ?? '-'}</span>
        </Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{item.address ?? '-'}</span>
        </Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{item?.phone ?? '-'} </span>
        </Table.Td>

        <Table.Td>
          {item.active && (
            <Badge p="12px" color="emerald.1" className="text-emerald-900">
              {t('status.active')}
            </Badge>
          )}
          {!item.active && (
            <Badge p="12px" color="gray.1" className="text-gray-900">
              {t('status.inactive')}
            </Badge>
          )}
        </Table.Td>
      </Table.Tr>
    ));
  }, [data, onRowClick, t]);

  return (
    <Paper radius="8px" withBorder className="overflow-hidden">
      <Table.ScrollContainer minWidth="70vw">
        <Table highlightOnHover classNames={{ th: 'font-medium py-3' }}>
          <Table.Thead className="bg-gray-50 text-gray-500 py-6">
            <Table.Tr>
              <Table.Th>{t('tables.id')}</Table.Th>
              <Table.Th>{t('tables.name')}</Table.Th>
              <Table.Th>{t('tables.institution-category')}</Table.Th>
              <Table.Th>{t('tables.address')}</Table.Th>
              <Table.Th>{t('tables.phone')}</Table.Th>
              <Table.Th>{t('tables.active')}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {isLoading && (
              <>
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
              </>
            )}
            {!isLoading && rows}
          </Table.Tbody>
        </Table>
        {!isLoading && _.isEmpty(data) && <EmptyState message={t('messages.no-results')} className="w-full" />}
      </Table.ScrollContainer>
    </Paper>
  );
}

InstitutionsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
};
InstitutionsTable.defaultProps = {
  data: [],
  isLoading: false,
  onRowClick: _.noop,
};

function RowSkeleton() {
  return (
    <Table.Tr>
      <Table.Td align="center">
        <div className="h-6 w-8 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-60 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-60 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
    </Table.Tr>
  );
}
