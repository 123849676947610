import PropTypes from 'prop-types';

export default function ChartTitle({ title }) {
  return <span className="text-gray-900 font-semibold text-base">{title}</span>;
}

ChartTitle.propTypes = {
  title: PropTypes.string,
};
ChartTitle.defaultProps = {
  title: '',
};
