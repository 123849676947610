/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo } from 'react';
import { useGetFile, useGetTranslations, useUpdateTranslation, useUploadFile } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useStateDisclosure } from 'utils';
import { ContentTable, ContentModal } from 'components/content';
import { FileModal } from 'components/files';
import { usefulDoc } from 'api/constants';

export default function Content() {
  const { page } = useParams();
  const navigate = useNavigate();
  const [modalOpened, modalHandler] = useStateDisclosure(false);
  const [fileModalOpened, fileModalHandler] = useStateDisclosure(false);
  const { file } = useGetFile({ fileName: usefulDoc.name });

  useEffect(() => {
    if (!page || page < 1) {
      navigate('/content/1', { replace: true });
    }
  }, [page, navigate]);
  const { t } = useTranslation();

  const { translations, isLoading, refetch } = useGetTranslations({ page });
  const onContentClick = useCallback(
    (item) => {
      modalHandler.open(item);
    },
    [modalHandler],
  );
  const onFileClick = useCallback(
    (item) => {
      fileModalHandler.open(item);
    },
    [fileModalHandler],
  );

  const { updateTranslation, isLoading: isUpdating } = useUpdateTranslation({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const { uploadFile, isLoading: isUploadingFile } = useUploadFile({
    onSuccess: () => {
      refetch();
      fileModalHandler.close();
    },
  });
  const updateTranslationSubmit = useCallback(
    (values) => {
      const { key, resourceKey, ...rest } = values;
      if (modalHandler.state && key && resourceKey) {
        updateTranslation({ resourceKey, key, payload: rest });
      }
    },
    [updateTranslation, modalHandler.state],
  );
  const onFileSubmit = useCallback(
    (value) => {
      if (value) {
        uploadFile(value);
      }
    },
    [uploadFile],
  );
  const tableData = useMemo(() => {
    const grouped = _.groupBy(translations?.data, 'resourceKey');
    return _.pick(grouped, ['Landing', 'Copyright']);
  }, [translations]);

  return (
    <div className="w-full bg-gray-100 ">
      <ContentModal
        opened={modalOpened}
        onClose={modalHandler.close}
        isSubmitting={isUpdating}
        translation={modalHandler.state}
        onSubmit={updateTranslationSubmit}
      />
      <FileModal
        opened={fileModalOpened}
        onClose={fileModalHandler.close}
        isSubmitting={isUploadingFile}
        file={fileModalHandler.state}
        onSubmit={onFileSubmit}
      />
      <div className="px-4 bg-white shadow">
        <div className="flex flex-col w-full items-center sm:flex-row justify-start sm:justify-between py-4 space-y-4 sm:space-y-0 ">
          <span className="self-start text-lg font-medium mr-4">{t('pages.content')}</span>
        </div>
      </div>
      <div className="p-6">
        <ContentTable
          data={tableData}
          file={file}
          isLoading={isLoading}
          onRowClick={onContentClick}
          onFileClick={onFileClick}
        />
      </div>
    </div>
  );
}
