import { useState, useCallback } from 'react';
import { FieldSelect } from 'components/form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useInstitutionCategories } from 'api/hooks';

export default function InstitutionCategoryFilter({ onChange, ...rest }) {
  const { categories, isLoading } = useInstitutionCategories({});
  const [selectedValue, setSelectedValue] = useState();

  const onSelect = useCallback(
    (option) => {
      setSelectedValue(option);
      onChange(option);
    },
    [onChange],
  );
  return (
    <FieldSelect
      value={selectedValue}
      onChange={(_value, option) => onSelect(option)}
      isLoading={isLoading}
      options={categories ?? []}
      focusColor="indigo"
      {...rest}
    />
  );
}

InstitutionCategoryFilter.propTypes = {
  onChange: PropTypes.func,
};
InstitutionCategoryFilter.defaultProps = {
  onChange: _.noop,
};
