import _ from 'lodash';
import PropTypes from 'prop-types';
import { Textarea } from '@mantine/core';

export default function TextAreaField({ value, onChange, ...rest }) {
  return (
    <Textarea
      classNames={{
        input:
          'outline-none border border-slate-300  shadow-sm focus:ring-1 focus:border-emerald-500 focus:ring-emerald-500 min-h-24',
      }}
      value={value ?? ''}
      onChange={(e) => onChange(e.currentTarget.value)}
      radius="6px"
      resize="vertical"
      {...rest}
    />
  );
}
TextAreaField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
TextAreaField.defaultProps = {
  value: '',
  onChange: _.noop,
};
