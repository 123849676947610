import { Paper, Button, Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconEdit } from '@tabler/icons-react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import QuestionnaireDetailsSection from './QuestionnaireDetailsSection';

export default function QuestionnaireDetails({ questionnaire, onEditClick, visibilityClasses }) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col space-y-4 ${visibilityClasses}`}>
      <Paper withBorder p="md" className="mb-4">
        <div className="flex flex-col space-y-4">
          <div className="w-full text-center text-3xl font-semibold text-gray-900">{questionnaire?.title}</div>
          <div className="max-w-full text-wrap mx-8 text-center text-lg font-normal text-gray-500">
            {questionnaire?.description}
          </div>
        </div>
      </Paper>

      {_.map(questionnaire?.sections, (item, index) => (
        <div key={item.code} className="flex flex-col space-y-4">
          <QuestionnaireDetailsSection key={item.code} index={index + 1} section={item} />
          <Divider />
        </div>
      ))}
      <div className="w-full flex justify-end">
        <Button
          className="w-min mt-4"
          onClick={onEditClick}
          color="indigo.6"
          radius="xl"
          leftSection={<IconEdit className="p-0.5" />}
        >
          {t('btn.edit')}
        </Button>
      </div>
    </div>
  );
}
QuestionnaireDetails.propTypes = {
  questionnaire: PropTypes.object,
  onEditClick: PropTypes.func,
  visibilityClasses: PropTypes.string,
};
QuestionnaireDetails.defaultProps = {
  questionnaire: null,
  onEditClick: _.noop,
  visibilityClasses: '',
};
