import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Paper, Table } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components/ui-messages';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';

const getFormattedDate = (date) => {
  return date ? format(parseISO(date), 'dd-MM-yyyy hh:mm') : '';
};
export default function QuestionnairesTable({ data, onRowClick, isLoading }) {
  const { t } = useTranslation();
  const rows = useMemo(() => {
    return _.map(data, (item) => (
      <Table.Tr
        key={item.id}
        className="text-gray-900 cursor-pointer"
        onClick={() => {
          onRowClick(item);
        }}
      >
        <Table.Td align="center">{item.id}</Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{item.title ?? '-'}</span>
        </Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{item.description ?? '-'}</span>
        </Table.Td>
        <Table.Td className="text-sm font-base">
          <span>{getFormattedDate(item.createdAt)}</span>
        </Table.Td>
      </Table.Tr>
    ));
  }, [data, onRowClick]);

  return (
    <Paper radius="8px" withBorder className="overflow-hidden">
      <Table.ScrollContainer minWidth="70vw">
        <Table highlightOnHover classNames={{ th: 'font-medium py-3' }}>
          <Table.Thead className="bg-gray-50 text-gray-500 py-6">
            <Table.Tr>
              <Table.Th>{t('tables.id')}</Table.Th>
              <Table.Th>{t('tables.title')}</Table.Th>
              <Table.Th>{t('tables.description')}</Table.Th>
              <Table.Th>{t('tables.created-at')}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {isLoading && (
              <>
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
                <RowSkeleton />
              </>
            )}
            {!isLoading && rows}
          </Table.Tbody>
        </Table>
        {!isLoading && _.isEmpty(data) && <EmptyState message={t('messages.no-results')} className="w-full" />}
      </Table.ScrollContainer>
    </Paper>
  );
}

QuestionnairesTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
};
QuestionnairesTable.defaultProps = {
  data: [],
  isLoading: false,
  onRowClick: _.noop,
};

function RowSkeleton() {
  return (
    <Table.Tr>
      <Table.Td align="center">
        <div className="h-6 w-8 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-60 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-60 animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
      <Table.Td>
        <div className="h-6 w-full animate-pulse rounded-full bg-slate-300" />
      </Table.Td>
    </Table.Tr>
  );
}
