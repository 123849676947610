import { useCallback, useState, useEffect } from 'react';
import { Button, Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { DatePicker } from '@mantine/dates';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function DateRangePicker({ onChange, dateFormat }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [range, setRange] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    const start = startDate ? format(new Date(startDate), dateFormat) : null;
    const end = endDate ? format(new Date(endDate), dateFormat) : null;
    const selectedRange = { startDate: start, endDate: end };
    setRange(selectedRange);
  }, [startDate, endDate, dateFormat]);

  const onRangeSelected = () => {
    onChange(range);
  };

  const onClear = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    onChange(null);
  }, [onChange]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col min-[500px]:flex-row min-[500px]:space-x-4 min-[500px]:space-y-0 space-y-4">
        <div className="flex flex-col space-y-2">
          <span className="flex-grow text-sm text-center font-semibold text-gray-400">{t('labels.from')}</span>
          <DatePicker
            value={startDate}
            maxDate={new Date()}
            size="xs"
            classNames={{
              day: 'data-[selected=true]:bg-indigo-600',
            }}
            onChange={(date) => {
              setStartDate(date ? new Date(date) : null);
            }}
            allowDeselect
          />
        </div>
        <Divider orientation="vertical" className="max-[500px]:hidden" />
        <Divider orientation="horizontal" className="min-[500px]:hidden" />
        <div className="flex flex-col space-y-2">
          <span className="flex-grow text-sm text-center font-semibold text-gray-400">{t('labels.to')}</span>
          <DatePicker
            size="xs"
            allowDeselect
            value={endDate}
            maxDate={new Date()}
            classNames={{
              day: 'data-[selected=true]:bg-indigo-600',
            }}
            onChange={(date) => {
              setEndDate(date ? new Date(date) : null);
            }}
          />
        </div>
      </div>
      <div className="flex space-x-4 justify-end my-4">
        <Button radius="xl" color="indigo.6" onClick={onRangeSelected}>
          {t('btn.apply')}
        </Button>
        <Button radius="xl" variant="subtle" color="gray.5" onClick={onClear}>
          {t('btn.clear')}
        </Button>
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
};
DateRangePicker.defaultProps = {
  onChange: _.noop,
  dateFormat: 'yyyy-MM-dd',
};
