import { useState, useEffect, useMemo } from 'react';
import { Modal, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useGetUser } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import UserForm from './UserForm';
import UserPasswordForm from './UserPasswordForm';
import UserDetails from './UserDetails';

export default function UserModal({ opened, onClose, userId, onSubmit, isSubmitting, onPasswordChange }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();
  const { user, isLoading, resetUser } = useGetUser(userId, { enabled: Boolean(userId) });

  useEffect(() => {
    if (!userId) {
      resetUser();
    }
  }, [userId, resetUser]);

  const initialVisibleComponent = useMemo(() => {
    return user ? 'userDetails' : 'userForm';
  }, [user]);

  const [visibleComponent, setVisibleComponent] = useState(initialVisibleComponent);

  useEffect(() => {
    setVisibleComponent(user ? 'userDetails' : 'userForm');
  }, [user]);
  const isCreateMode = useMemo(() => !user, [user]);

  const modalTitle = useMemo(() => {
    const formVisible = visibleComponent === 'userForm' || visibleComponent === 'passwordForm';
    if (formVisible) {
      return isCreateMode ? t('titles.create-user') : t('titles.edit-user');
    }
    return t('titles.user-details');
  }, [visibleComponent, isCreateMode, t]);
  return (
    <Modal
      size="60%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent('userForm');
      }}
      centered
      keepMounted={false}
      title={modalTitle}
    >
      {isLoading && (
        <div className="flex justify-center items-center p-40">
          <Loader color="indigo.6" />
        </div>
      )}
      {!isLoading && (
        <VisibilityWrapper value={visibleComponent}>
          <UserDetails componentKey="userDetails" user={user} onEditClick={() => setVisibleComponent('userForm')} />
          <UserForm
            user={user}
            componentKey="userForm"
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            onChangePasswordClick={() => setVisibleComponent('passwordForm')}
            onBackClick={() => setVisibleComponent('userDetails')}
          />
          <UserPasswordForm
            user={user}
            componentKey="passwordForm"
            onClose={() => setVisibleComponent('userForm')}
            onSubmit={onPasswordChange}
            isSubmitting={isSubmitting}
          />
        </VisibilityWrapper>
      )}
    </Modal>
  );
}

UserModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPasswordChange: PropTypes.func,
  userId: PropTypes.number,
};

UserModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  onPasswordChange: _.noop,
  userId: null,
};
