import { Burger, Avatar, UnstyledButton, Button, Menu, rem, MenuDivider } from '@mantine/core';
import { IconBell, IconLogout, IconSettings, IconUser } from '@tabler/icons-react';

import { AppLogoLight } from 'components/icons';
import { useAuthContext } from 'auth';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function UserButton() {
  const { logout, user } = useAuthContext();
  const navigate = useNavigate();

  return (
    <Menu width={260} position="bottom-end" transitionProps={{ transition: 'pop-top-right' }} withinPortal>
      <Menu.Target>
        <UnstyledButton className="">
          <Avatar radius="xl" size="32px" color="white" className="hover:opacity-80" />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <div className="flex flex-col">
            <span className="font-bold">
              {user?.firstName} {user?.lastName}
            </span>
            <span>{user?.email}</span>
          </div>
        </Menu.Label>
        <MenuDivider />
        <Menu.Item
          onClick={() => navigate('/profile')}
          leftSection={<IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        >
          Setări
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            logout();
          }}
          color="red"
          leftSection={<IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
export default function Navbar({ opened, toggle }) {
  const { isLoggedIn, login, isFetchingUser } = useAuthContext();
  const { t } = useTranslation();

  return (
    <div className="h-full p-4 flex items-center bg-indigo-600">
      <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" className="mr-4" color="white" />
      <AppLogoLight />

      <div className="flex flex-grow justify-end space-x-3">
        {isLoggedIn && (
          <>
            <div className="size-8 p-1">
              <IconBell size="100%" className="text-white" />
            </div>
            <UserButton />
          </>
        )}
        {!isLoggedIn && !isFetchingUser && (
          <Button
            variant="transparent"
            color="unstyled"
            className="text-base font-semibold text-white bg-transparent"
            leftSection={<IconUser className="size-5" />}
            onClick={login}
          >
            {t('btn.auth')}
          </Button>
        )}
        {isFetchingUser && <div className="size-8 animate-pulse rounded-full bg-slate-300" />}
      </div>
    </div>
  );
}

Navbar.propTypes = {
  opened: PropTypes.bool,
  toggle: PropTypes.func,
};

Navbar.defaultProps = {
  opened: false,
  toggle: _.noop,
};
