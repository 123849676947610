/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import { SegmentedTabs, PermissionGuard } from 'components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';
import _ from 'lodash';
import { format } from 'date-fns';
import { notifications } from '@mantine/notifications';
import { IconX, IconCheck, IconFileTypePdf } from '@tabler/icons-react';
import { rem, Group, Grid, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import {
  GeneralStatsCards,
  PatientSatisfactionStats,
  InstitutionSectionsStats,
  UnofficialPaymentsStats,
  NonInsurancePaymentsStats,
  FacilitiesStats,
  YearEvolutionStats,
  HospitalizationMethodStats,
  InstitutionsTopStats,
} from 'components/stats';
import {
  InstitutionFilter,
  InstitutionCategoryFilter,
  AgeGroupFilter,
  InstitutionSectionsFilter,
} from 'components/filters';
import { useDashboardKeys } from 'auth/hooks/useDashboardKeys';
import { roles } from 'auth';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHasRole } from 'auth/hooks/useHasRole';

export default function Dashboard() {
  const [periodFilter, setPeriodFilter] = useState({});
  const [institutionCategory, setInstitutionCategory] = useState({});
  const [institutionFilter, setInstitutionFilter] = useState();
  const [departmentFilter, setDepartmentFilter] = useState();
  const [ageGroupFilter, setAgeGroupFilter] = useState();
  const [filter, setFilter] = useState();
  const isAdmin = useHasRole([roles.admin]);

  useEffect(() => {
    const updatedFilter = {};

    if (periodFilter?.startDate) {
      updatedFilter.DateFrom = periodFilter.startDate;
    }
    if (periodFilter?.endDate) {
      updatedFilter.DateTo = periodFilter.endDate;
    }
    if (institutionCategory) {
      updatedFilter.Category = institutionCategory;
    }
    if (institutionFilter) {
      updatedFilter.Institution = institutionFilter;
    }
    if (departmentFilter) {
      updatedFilter.Department = departmentFilter;
    }
    if (ageGroupFilter) {
      updatedFilter.AgeGroup = ageGroupFilter;
    }
    setFilter(updatedFilter);
  }, [periodFilter, institutionCategory, institutionFilter, departmentFilter, ageGroupFilter]);
  const dashboardKeys = useDashboardKeys();
  const { t } = useTranslation();

  return (
    <div className="w-full bg-gray-100 ">
      <div className="px-4 bg-white shadow">
        <Group justify="space-between" className="py-4">
          <span className="text-lg font-medium">Dashboard</span>
          <SegmentedTabs onChange={setPeriodFilter} />
        </Group>
        <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 sm:justify-end  pb-4">
          <PermissionGuard role={roles.admin}>
            <InstitutionCategoryFilter
              placeholder="Categoria Institutiei"
              onChange={(option) => {
                setInstitutionCategory(option?.item?.id);
              }}
            />
            <InstitutionFilter
              placeholder="Institutie"
              onChange={(option) => {
                setInstitutionFilter(option?.item?.id);
              }}
            />
          </PermissionGuard>
          <PermissionGuard role={roles.clinic}>
            <InstitutionSectionsFilter
              placeholder="Secția"
              onChange={(option) => {
                setDepartmentFilter(option?.item?.id);
              }}
            />
          </PermissionGuard>
          <AgeGroupFilter
            placeholder="Grupa de vârstă"
            onChange={(option) => {
              setAgeGroupFilter(option?.item?.id);
            }}
          />
        </div>
        <div className="flex justify-end pb-4">
          <DashboardStatsExport filter={filter} />
        </div>
      </div>
      <div className="p-6 grid grid-cols-2  auto-cols-min	gap-4">
        <GeneralStatsCards filter={filter} />
        <Grid grow gutter="xs" className="col-span-2">
          <Grid.Col span={4}>
            <PatientSatisfactionStats filter={filter} />
          </Grid.Col>
          <Grid.Col span={4}>
            <UnofficialPaymentsStats filter={filter} />
          </Grid.Col>
          <PermissionGuard role={roles.admin}>
            <Grid.Col span={4}>
              <HospitalizationMethodStats
                title={t('stats.titles.hospitalization-mode')}
                queryKey={dashboardKeys.hospitalizationMode?.[0]?.key}
                filter={filter}
              />
            </Grid.Col>
          </PermissionGuard>
        </Grid>
        <div className="col-span-2">
          <InstitutionSectionsStats filter={filter} />
        </div>
        <PermissionGuard role={roles.admin}>
          <div className="col-span-2">
            <YearEvolutionStats
              title={t('stats.titles.year-evolution')}
              queryKey={dashboardKeys.yearStatsEvolution?.[0]?.key}
              filter={filter}
            />
          </div>
        </PermissionGuard>

        <div className="sm:col-span-1 col-span-2">
          <NonInsurancePaymentsStats
            title={t('stats.titles.non-insurance-payments')}
            queryKey={dashboardKeys.nonInsurancePayments?.[0]?.key}
            filter={filter}
          />
        </div>
        <div className="sm:col-span-1 col-span-2">
          <UnofficialPaymentsStats.ByCategories
            title={t('stats.titles.unofficial-payments')}
            queryKey={dashboardKeys.unofficialPaymentsCategories?.[0]?.key}
            filter={filter}
          />
        </div>
        <PermissionGuard role={roles.admin}>
          <div className="sm:col-span-1 col-span-2">
            <NonInsurancePaymentsStats
              title={t('stats.titles.non-insurance-payments_amp')}
              queryKey={dashboardKeys.nonInsurancePayments?.[1]?.key}
              filter={filter}
            />
          </div>
          <div className="sm:col-span-1 col-span-2">
            <UnofficialPaymentsStats.ByCategories
              title={t('stats.titles.unofficial-payments_amp')}
              queryKey={dashboardKeys.unofficialPaymentsCategories?.[1]?.key}
              filter={filter}
            />
          </div>
        </PermissionGuard>
        <div
          className={classNames('col-span-2 md:col-span-1 h-full', {
            'md:col-span-1': isAdmin,
          })}
        >
          <FacilitiesStats
            title={t('stats.titles.facilities')}
            queryKey={dashboardKeys.facilitiesStats?.[0]?.key}
            filter={filter}
          />
        </div>
        <PermissionGuard role={roles.admin}>
          <div className="col-span-2 md:col-span-1 h-full">
            <FacilitiesStats
              title={t('stats.titles.facilities_amp')}
              queryKey={dashboardKeys.facilitiesStats?.[1]?.key}
              filter={filter}
            />
          </div>
        </PermissionGuard>

        <PermissionGuard role={roles.admin}>
          <div className="col-span-2">
            <InstitutionsTopStats queryKey={dashboardKeys.institutionsTop?.[0]?.key} filter={filter} scrollable />
          </div>
        </PermissionGuard>
      </div>
    </div>
  );
}

const getImageDimensions = (imgData) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = imgData;
  });
};

const getImageData = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
function DashboardStatsExport({ filter }) {
  const dashboardKeys = useDashboardKeys();
  const { t } = useTranslation();
  const isAdmin = useHasRole([roles.admin]);
  const [downloading, setDownloading] = useState();

  const mainStatsRef = useRef();
  const tableRef = useRef();
  const mainRef = useRef();

  const downloadPDF = useCallback(async () => {
    setDownloading(true);
    const doc = new jsPDF();
    const mainStatsCapture = await htmlToImage
      .toBlob(mainStatsRef.current)
      .then((blob) => getImageData(blob))
      .catch((e) => {
        console.log('Error Saving Main Stats: ', e);
      });
    const tableCapture = await htmlToImage
      .toBlob(tableRef.current)
      .then((blob) => getImageData(blob))
      .catch((e) => {
        console.log('Error Saving Main Stats: ', e);
      });

    const insertImage = async (pdf, imgData) => {
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const { width, height } = await getImageDimensions(imgData);
      const scaleFactor = Math.min(pageWidth / width, pageHeight / height);
      const imgWidth = width * scaleFactor;
      const imgHeight = height * scaleFactor;
      pdf.addImage(imgData, 'PNG', (pageWidth - imgWidth) / 2, (pageHeight - imgHeight) / 2, imgWidth, imgHeight);
    };
    await insertImage(doc, mainStatsCapture);
    doc.addPage();
    await insertImage(doc, tableCapture);
    doc.save('raport-dashboard.pdf');
    setDownloading(false);
    notifications.show({
      withCloseButton: true,
      autoClose: 3000,
      title: 'Descărcare cu succes!',
      message: 'Raportul a fost descărcat cu succes',
      color: 'emerald.5',
      icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
    });
  }, []);
  return (
    <>
      <Button
        className="w-min"
        variant="outline"
        color="gray.5"
        radius="xl"
        onClick={downloadPDF}
        leftSection={<IconFileTypePdf className="size-22" />}
        loading={downloading}
      >
        {t('btn.download-pdf')}
      </Button>

      <div
        ref={mainRef}
        style={{
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
          width: '1880px',
          height: 'max-content',
        }}
      >
        <div id="main-stats" className="w-full h-full overflow-visible">
          <div ref={mainStatsRef} className="p-6 grid grid-cols-2  auto-cols-min 	gap-4 bg-white">
            <div className="col-span-2 flex ">
              <p className="mb-8 font-bold text-4xl flex-grow text-center">
                Raport din {format(filter?.DateFrom ? new Date(filter?.DateFrom) : Date.now(), 'dd-MM-yyyy')}
              </p>
            </div>
            <GeneralStatsCards filter={filter} />
            <Grid grow gutter="xs" className="col-span-2">
              <Grid.Col span={4}>
                <PatientSatisfactionStats filter={filter} withLegend />
              </Grid.Col>
              <Grid.Col span={4}>
                <UnofficialPaymentsStats filter={filter} withLegend />
              </Grid.Col>
              <PermissionGuard role={roles.admin}>
                <Grid.Col span={4}>
                  <HospitalizationMethodStats
                    title={t('stats.titles.hospitalization-mode')}
                    queryKey={dashboardKeys.hospitalizationMode?.[0]?.key}
                    filter={filter}
                    withLegend
                  />
                </Grid.Col>
              </PermissionGuard>
            </Grid>
            <div className="col-span-2">
              <InstitutionSectionsStats filter={filter} withLegend />
            </div>
            <PermissionGuard role={roles.admin}>
              <div className="col-span-2">
                <YearEvolutionStats
                  title={t('stats.titles.year-evolution')}
                  queryKey={dashboardKeys.yearStatsEvolution?.[0]?.key}
                  filter={filter}
                  withLegend
                />
              </div>
            </PermissionGuard>

            <div className="sm:col-span-1 col-span-2">
              <NonInsurancePaymentsStats
                title={t('stats.titles.non-insurance-payments')}
                queryKey={dashboardKeys.nonInsurancePayments?.[0]?.key}
                filter={filter}
              />
            </div>
            <div className="sm:col-span-1 col-span-2">
              <UnofficialPaymentsStats.ByCategories
                title={t('stats.titles.unofficial-payments')}
                queryKey={dashboardKeys.unofficialPaymentsCategories?.[0]?.key}
                filter={filter}
              />
            </div>
            <PermissionGuard role={roles.admin}>
              <div className="sm:col-span-1 col-span-2">
                <NonInsurancePaymentsStats
                  title={t('stats.titles.non-insurance-payments_amp')}
                  queryKey={dashboardKeys.nonInsurancePayments?.[1]?.key}
                  filter={filter}
                />
              </div>
              <div className="sm:col-span-1 col-span-2">
                <UnofficialPaymentsStats.ByCategories
                  title={t('stats.titles.unofficial-payments_amp')}
                  queryKey={dashboardKeys.unofficialPaymentsCategories?.[1]?.key}
                  filter={filter}
                />
              </div>
            </PermissionGuard>
            <div
              className={classNames('col-span-2 md:col-span-1 h-full', {
                'md:col-span-1': isAdmin,
              })}
            >
              <FacilitiesStats
                title={t('stats.titles.facilities')}
                queryKey={dashboardKeys.facilitiesStats?.[0]?.key}
                filter={filter}
              />
            </div>
            <PermissionGuard role={roles.admin}>
              <div className="col-span-2 md:col-span-1 h-full">
                <FacilitiesStats
                  title={t('stats.titles.facilities_amp')}
                  queryKey={dashboardKeys.facilitiesStats?.[1]?.key}
                  filter={filter}
                />
              </div>
            </PermissionGuard>
          </div>
        </div>
        <PermissionGuard role={roles.admin}>
          <div id="institutions-top-table" className="w-max p-8">
            <InstitutionsTopStats
              ref={tableRef}
              queryKey={dashboardKeys.institutionsTop?.[0]?.key}
              filter={filter}
              scrollable={false}
            />
          </div>
        </PermissionGuard>
      </div>
    </>
  );
}
DashboardStatsExport.propTypes = {
  filter: PropTypes.object,
};

DashboardStatsExport.defaultProps = {
  filter: {},
};
