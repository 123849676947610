import { Paper, Button, Anchor } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconEdit, IconMap2, IconMail, IconPrinter, IconPhone, IconLink } from '@tabler/icons-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function InstitutionDetails({ institution, onEditClick, visibilityClasses }) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col space-y-4 ${visibilityClasses}`}>
      <span className="text-lg font-semibold text-gray-900 w-full text-center">{institution?.name}</span>
      <Paper withBorder p="md">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <IconMap2 className="size-6" />
            <span className="text-md text-gray-500">{institution?.fullAddress}</span>
          </div>
          <div className="flex items-center space-x-2">
            <IconMail className="size-6" />
            <span className="text-md text-gray-500">{institution?.eMail}</span>
          </div>
          <div className="flex items-center space-x-2">
            <IconPhone className="size-6" />
            <span className="text-md text-gray-500">{institution?.phone ?? '-'}</span>
          </div>
          <div className="flex items-center space-x-2">
            <IconPrinter className="size-6" />
            <span className="text-md text-gray-500">{institution?.fax ?? '-'}</span>
          </div>
          <div className="flex items-center space-x-2">
            <IconLink className="size-6" />
            <Anchor
              underline="hover"
              href={institution?.url}
              target="_blank"
              className="text-md text-gray-500"
              rel="noreferrer"
            >
              {institution?.url ?? '-'}
            </Anchor>
          </div>
        </div>
      </Paper>
      <div className="w-full flex justify-end">
        <Button
          className="w-min mt-4"
          onClick={onEditClick}
          color="indigo.6"
          radius="xl"
          leftSection={<IconEdit className="p-0.5" />}
        >
          {t('btn.edit')}
        </Button>
      </div>
    </div>
  );
}
InstitutionDetails.propTypes = {
  institution: PropTypes.object,
  onEditClick: PropTypes.func,
  visibilityClasses: PropTypes.string,
};
InstitutionDetails.defaultProps = {
  institution: null,
  onEditClick: _.noop,
  visibilityClasses: '',
};
