import { Anchor } from '@mantine/core';
import { AppLogoImage } from 'components/icons';

export default function AppLogo() {
  return (
    <Anchor href="/" underline="never">
      <AppLogoImage />
    </Anchor>
  );
}
