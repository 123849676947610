/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuestionnaires, useCreateQuestionnaire, useUpdateQuestionnaire } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Pagination, Popover, Input, Button } from '@mantine/core';
import { IconCalendar, IconSearch, IconPlus } from '@tabler/icons-react';
import { useStateDisclosure } from 'utils';
import { DateRangePicker } from 'components/date-range-picker';
import { useDebouncedValue } from '@mantine/hooks';
import { QuestionnairesTable, QuestionnaireModal } from 'components/questionnaire';

export default function Questionnaires() {
  const { page } = useParams();
  const navigate = useNavigate();
  const [modalOpened, modalHandler] = useStateDisclosure(false);

  useEffect(() => {
    if (!page || page < 1) {
      navigate('/questionnaires/1', { replace: true });
    }
  }, [page, navigate]);
  const { t } = useTranslation();

  const { questionnaires, isLoading, refetch } = useQuestionnaires({ page });
  const totalPages = useMemo(
    () => Math.ceil(_.divide(questionnaires?.count, questionnaires?.pageSize)),
    [questionnaires],
  );
  const onQuestionnaireClick = useCallback(
    (questionnaire) => {
      modalHandler.open(questionnaire);
    },
    [modalHandler],
  );
  const { createQuestionnaire, isLoading: isCreatingQuestionnaire } = useCreateQuestionnaire({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const { updateQuestionnaire, isLoading: isUpdatingQuestionnaire } = useUpdateQuestionnaire({
    onSuccess: () => {
      refetch();
      modalHandler.close();
    },
  });
  const createQuestionnaireSubmit = useCallback(
    (values) => {
      createQuestionnaire(values);
    },
    [createQuestionnaire],
  );
  const updateQuestionnaireSubmit = useCallback(
    (values) => {
      if (modalHandler.state) {
        updateQuestionnaire({ id: modalHandler.state?.id, payload: values });
      }
    },
    [updateQuestionnaire, modalHandler.state],
  );

  return (
    <div className="w-full bg-gray-100 ">
      <QuestionnaireModal
        opened={modalOpened}
        onClose={modalHandler.close}
        isSubmitting={isCreatingQuestionnaire || isUpdatingQuestionnaire}
        questionnaire={modalHandler.state}
        onSubmit={modalHandler.state ? updateQuestionnaireSubmit : createQuestionnaireSubmit}
      />
      <div className="px-4 bg-white shadow">
        <div className="flex flex-col w-full items-center sm:flex-row justify-start sm:justify-between py-4 space-y-4 sm:space-y-0 ">
          <span className="self-start text-lg font-medium mr-4">{t('pages.questionnaires')}</span>
          <Button
            className="w-min"
            variant="outline"
            color="gray.5"
            radius="xl"
            leftSection={<IconPlus className="p-0.5" />}
            onClick={() => modalHandler.open()}
          >
            {t('btn.questionnaire-add')}
          </Button>
        </div>
      </div>
      <div className="p-6">
        <QuestionnairesTable
          data={questionnaires?.data ?? []}
          isLoading={isLoading}
          onRowClick={onQuestionnaireClick}
        />
        <div className="flex justify-end py-4">
          <Pagination
            total={totalPages}
            color="gray.3"
            classNames={{
              control:
                'data-[active=true]:border-2 data-[active=true]:border-indigo-600 data-[active=true]:bg-indigo-100 data-[active=true]:text-indigo-600 text-gray-900',
            }}
            autoContrast
            onChange={(value) => {
              navigate(`/questionnaires/${value}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
