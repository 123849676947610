import { useCallback, useMemo } from 'react';
import { useInstitutions, useUpdateCurrentUser, useUpdateUserPassword } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Paper, Container } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useStateDisclosure } from 'utils';
import { useAuthContext } from 'auth';
import { ProfileModal, ProfileDetailsItem } from 'components/profile-settings';

export default function ProfileSettings() {
  const [modalOpened, modalHandler] = useStateDisclosure(false);
  const { user, refetchUser } = useAuthContext();
  const { t } = useTranslation();
  const { institutions } = useInstitutions({});
  const institution = useMemo(() => {
    return _.find(institutions, ['id', user?.structure]);
  }, [institutions, user]);

  const { updateUser, isLoading: isUpdatingUser } = useUpdateCurrentUser({
    onSuccess: () => {
      refetchUser();
      modalHandler.close();
    },
  });
  const { updateUserPassword, isLoading: isUpdatingPassword } = useUpdateUserPassword({
    onSuccess: () => {
      refetchUser();
      modalHandler.close();
    },
  });
  const onUserUpdateSubmit = useCallback(
    (values) => {
      updateUser(values);
    },
    [updateUser],
  );
  const onPasswordSubmit = useCallback(
    (values) => {
      updateUserPassword(values);
    },
    [updateUserPassword],
  );
  return (
    <div className="w-full bg-gray-100 ">
      <ProfileModal
        opened={modalOpened}
        onClose={modalHandler.close}
        user={user}
        isSubmitting={isUpdatingUser || isUpdatingPassword}
        onSubmit={onUserUpdateSubmit}
        onPasswordChange={onPasswordSubmit}
      />
      <div className="px-4 bg-white shadow">
        <div className="flex flex-col w-full items-center sm:flex-row justify-start sm:justify-between py-4 space-y-4 sm:space-y-0 ">
          <span className="self-start text-lg font-medium mr-4">{t('pages.profile')}</span>
        </div>
      </div>
      <Container size="md" className="my-6">
        <div className="flex flex-col space-y-4">
          <Paper withBorder p="xl" shadow="sm" radius="8px">
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Detalii profil</h3>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <ProfileDetailsItem label={t('labels.last-name')} value={user?.lastName} />
                <ProfileDetailsItem label={t('labels.first-name')} value={user?.firstName} />
                <ProfileDetailsItem label={t('labels.email')} value={user?.email} />
                <ProfileDetailsItem label={t('labels.institution-name')} value={institution?.name} />
                <ProfileDetailsItem label={t('labels.roles')} value={_.join(_.map(user?.roles, 'code'), ', ')} />
              </dl>
            </div>

            <div className="w-full flex justify-center">
              <Button
                className="w-min mt-4"
                color="indigo.6"
                onClick={() => {
                  modalHandler.open();
                }}
                radius="xl"
                leftSection={<IconEdit className="p-0.5" />}
              >
                {t('btn.edit')}
              </Button>
            </div>
          </Paper>
        </div>
      </Container>
    </div>
  );
}
