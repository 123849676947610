import { useCallback, useState, useMemo, useEffect } from 'react';
import { Tabs, Button, Popover } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { subDays, startOfDay, subMonths, startOfMonth, endOfMonth, format } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'components/date-range-picker';
import classNames from 'classnames';
import classes from './SegmentedTabs.module.css';

const dateFormat = 'yyyy-MM-dd';

export default function SegmentedTabs({ onChange, ...rest }) {
  const { t } = useTranslation();
  const periods = useMemo(
    () => ({
      month: {
        title: t('periods.month'),
        getPeriod: () => {
          const currentDate = new Date();
          const startDate = subDays(startOfDay(currentDate), 29);
          return { startDate: format(startDate, dateFormat), endDate: format(currentDate, dateFormat) };
        },
      },
      trimester: {
        title: t('periods.trimester'),
        getPeriod: () => {
          const currentDate = new Date();
          const startDateOfLastTrimester = subMonths(startOfMonth(currentDate), 2);
          const endDateOfLastTrimester = endOfMonth(currentDate);
          return {
            startDate: format(startDateOfLastTrimester, dateFormat),
            endDate: format(endDateOfLastTrimester, dateFormat),
          };
        },
      },
      annual: {
        title: t('periods.annual'),
        getPeriod: () => {
          const currentDate = new Date();
          const startDate = subDays(currentDate, 365);
          return { startDate: format(startDate, dateFormat), endDate: format(currentDate, dateFormat) };
        },
      },
    }),
    [t],
  );
  const [selectedTab, setSelectedTab] = useState('month');
  const [customDateRange, setCustomDateRange] = useState({});
  const [pickerOpened, setPickerOpened] = useState(false);

  const onRangeChanged = useCallback((range) => {
    setCustomDateRange(range);
    setPickerOpened(false);
  }, []);

  useEffect(() => {
    onChange(customDateRange);
  }, [onChange, customDateRange]);

  const onTabChanged = useCallback(
    (tab) => {
      setSelectedTab(tab);
      if (periods[tab]) {
        onChange(periods[tab].getPeriod());
      } else {
        onChange(customDateRange);
      }
    },
    [periods, onChange, customDateRange],
  );
  const customRangeTitle = useMemo(() => {
    if (_.isEmpty(customDateRange)) {
      return t('periods.custom');
    }
    const from = customDateRange?.startDate ?? '';
    const to = customDateRange?.endDate ?? t('labels.today');
    return _.join([from, to].filter(Boolean), ' | ');
  }, [t, customDateRange]);

  return (
    <Tabs variant="unstyled" defaultValue="month" onChange={onTabChanged} {...rest}>
      <Tabs.List grow>
        {_.map(_.keys(periods), (periodKey) => (
          <Tabs.Tab key={periodKey} className={classes.tab} value={periodKey}>
            {periods[periodKey].title}
          </Tabs.Tab>
        ))}
        <Tabs.Tab
          className={classes.tab}
          style={{ padding: 0 }}
          styles={{ tabLabel: { height: '100%' } }}
          value="custom"
        >
          <Popover keepMounted shadow="xl" opened={pickerOpened} onClose={() => setPickerOpened(false)}>
            <Popover.Target>
              <Button
                component="div"
                variant="transparent"
                onClick={() => setPickerOpened((prev) => !prev)}
                className={classNames('h-full w-full px-1.5 py-2.5', {
                  'text-gray-900': selectedTab !== 'custom',
                  'text-indigo-600': selectedTab === 'custom',
                })}
                leftSection={<IconCalendar size="18px" />}
              >
                {customRangeTitle}
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <DateRangePicker onChange={onRangeChanged} />
            </Popover.Dropdown>
          </Popover>
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}
SegmentedTabs.propTypes = {
  onChange: PropTypes.func,
};
SegmentedTabs.defaultProps = {
  onChange: _.noop,
};
