/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import { AppShell, Group, ActionIcon } from '@mantine/core';
import { useScrollIntoView, useWindowScroll, useMediaQuery } from '@mantine/hooks';
import { AppLogoImage, IconUser } from 'components/icons';

import { useTranslation } from 'react-i18next';
import { Layout, Button } from 'components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'auth';
import { useGetPublicTranslation } from 'api/hooks';

import {
  Cover,
  VideoIntroductionContainer,
  CTAContainer,
  ExplanationContainer,
  PatientRights,
  LinksContainer,
} from './sections';

const layoutPadding = '2xl:px-32 md:px-12 px-6';

export default function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hideAuthBtnText = useMediaQuery('(max-width: 485px)');
  const { translation: copyright } = useGetPublicTranslation({ resourceKey: 'Copyright', key: 'CopyrightText' });

  const { login, isLoggedIn } = useAuthContext();
  const navigateToQuestionnaire = useCallback(() => {
    navigate('/questionnaire');
  }, [navigate]);

  const { scrollIntoView, targetRef } = useScrollIntoView({});
  const scrollToExplanation = useCallback(() => {
    scrollIntoView();
  }, [scrollIntoView]);
  const [scroll] = useWindowScroll();

  return (
    <AppShell
      classNames={{ header: 'bg-transparent' }}
      styles={{ header: { backgroundColor: 'transparent' } }}
      header={{ height: '90px', offset: false }}
      withBorder={false}
    >
      <AppShell.Header>
        <div
          className={classNames(
            'h-full w-full',
            `transition-all duration-300 ${scroll.y > 150 ? 'bg-white shadow-md' : 'bg-transparent'}`,
          )}
        >
          <Group h="100%" className={classNames(layoutPadding, 'max-w-[1536px] mx-auto')} justify="space-between">
            <AppLogoImage className="w-52 xs:w-max" />
            {!hideAuthBtnText && (
              <Button
                variant="transparent"
                color="unstyled"
                className="text-base font-semibold text-gray-900 bg-transparent"
                leftSection={<IconUser className="size-5" />}
                onClick={isLoggedIn ? () => navigate('/dashboard') : login}
              >
                {t('btn.auth')}
              </Button>
            )}
            {hideAuthBtnText && (
              <ActionIcon variant="transparent" className="text-gray-900">
                <IconUser className="size-5" />{' '}
              </ActionIcon>
            )}
          </Group>
        </div>
      </AppShell.Header>
      <AppShell.Main>
        <div className="h-full w-full max-w-[1536px] mx-auto md:pt-0">
          <Cover
            className={classNames(layoutPadding, 'pt-12 md:pt-0')}
            onQuestionnaireClick={navigateToQuestionnaire}
            onLearnMoreClick={scrollToExplanation}
          />
          <VideoIntroductionContainer className={layoutPadding} />
          <CTAContainer className={layoutPadding} onQuestionnaireClick={navigateToQuestionnaire} />
          <ExplanationContainer className={layoutPadding} ref={targetRef} />
          <PatientRights className={layoutPadding} translationKey="PatientRights" itemsSize={19} />
          <PatientRights
            className={layoutPadding}
            bgColor="white"
            translationKey="PatientResponsibilities"
            itemsSize={10}
          />
          <CTAContainer className={layoutPadding} onQuestionnaireClick={navigateToQuestionnaire} withImage />
          <LinksContainer className={layoutPadding} />
          <div className={classNames(layoutPadding, 'flex  bg-white items-center justify-center py-12')}>
            <span className="text-base font-medium text-zinc-400 text-center">{copyright?.text}</span>
          </div>
        </div>
      </AppShell.Main>
    </AppShell>
  );
}
